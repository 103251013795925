import React, { useEffect } from "react";
//import logo from './logo.svg';
//import './App.css';
// import { render } from '@testing-library/react';
import { mainReducer } from "./Reducers/main_reducer";
import { defaultState } from "./Store/defaultStatus";
import { StateProvider, useStateContext } from "./Store/StateProvider";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./Layout/Layout";

const App = () => {

  return (
    <StateProvider reducer={mainReducer} initialState={defaultState}>
      <Router>
        <Layout></Layout>
      </Router>
    </StateProvider>
  );
};

export default App;
