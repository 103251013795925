import { defaultStateType } from "../Store/defaultStatus";

export function reducer(state: any, action: any) {
  console.log('this is orderReducer!')

  switch (action.type) {
    case "ORDER_RELOAD":
      return { ...state, ...action.playload };
    case "ORDER":
      return { ...state, ...action.playload };
    default:
      return state;
  }
}

export function reloadOrderListAction(object: object) {
  return { type: "ORDER_RELOAD", playload: object };
}

export function orderAction(object: defaultStateType) {
  return { type: "ORDER", playload: object };
}
