import React, { useEffect, useState } from "react";
import { Button, List, Modal, Popconfirm } from "antd";
import AddOrderItem from "./AddOrderItem";
import { useStateContext } from "../../Store/StateProvider";
import { useHistory } from "react-router-dom";
import { getDataForTable } from "../../Utils/getDataForTable";
import { orderAction } from "../../Reducers/order_reducer";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function OrderDetail(props: any) {
  let [modalVisiable, setModalVisiable] = useState(false);
  let [state, dispatch] = useStateContext();
  let history = useHistory();
  let [data, setData] = useState({ total: 0, items: [], finished: false });
  let [, setPageTotal] = useState();
  let [finishBtnLoading, setFinishBtnLoading] = useState(false);
  let [delConfirmVisiable, setDelConfirmVisiable] = useState(false);
  let [delItemConfirmVisiable, setItemDelConfirmVisiable] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
  ] = Object.values(state.order);
  useEffect(() => {
    getDataForTable(
      {
        pageSize,
        pageNo,
        searchField: "id",
        searchContent: state.order.currentID,
        between,
        conditionType,
        queryType,
        orderBy,
        sortBy,
      },
      setData,
      setPageTotal,
      "/api/order",
      "GET"
    );
    // return () => dispatch(orderAction({ currentID: null }));
  }, [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    state.order.reloadFlag,
  ]);

  function finishOrder() {
    axios
      .put("/api/finishorder", { id: state.order.currentID, finished: true })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            //Modal.info({ title: "Add user success" });
            setModalVisiable(false);

            dispatch(
              orderAction({
                pageNo: 1,
                reloadFlag: !state.order.reloadFlag,
                itemID: null,
              })
            );
          } else {
            Modal.error({
              title: "Edit record failed.",
              content: JSON.stringify(v.data.msg),
            });
          }
          setFinishBtnLoading(false);
        }
      })
      .catch((e) => {
        Modal.error({ title: "Edit record failed." });
        setFinishBtnLoading(false);
      });
  }

  function deleteOrder() {
    axios
      .delete("/api/order", {
        data: { id: state.order.currentID },
      })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            dispatch(
              orderAction({
                pageNo: 1,
                reloadFlag: !state.order.reloadFlag,
                itemID: null,
                currentID: null,
                order_no: null,
              })
            );
            history.push("/main");
          } else {
            Modal.error({
              title: "Delete record failed.",
              content: JSON.stringify(v.data.msg),
            });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Delete record server error." });
      });
  }

  function delteItem(id: number, name: string) {
    Modal.confirm({
      title: "你確定要刪除以下項目嗎?",
      icon: <ExclamationCircleOutlined />,
      content: name,
      onOk() {
        // let index = data.items.findIndex((i: any) => i.id === id);
        // data.items.splice(index, 1);
        axios
          .delete("/api/orderitem", {
            data: { itemId: id, orderId: state.order.currentID },
          })
          .then((v) => {
            if (v.status === 200) {
              if (v.data.success) {
                //Modal.info({ title: "Add user success" });
                setModalVisiable(false);

                dispatch(
                  orderAction({
                    pageNo: 1,
                    reloadFlag: !state.order.reloadFlag,
                    itemID: null,
                  })
                );
              } else {
                Modal.error({
                  title: "Edit record failed.",
                  content: JSON.stringify(v.data.msg),
                });
              }
            }
          })
          .catch((e) => {
            Modal.error({ title: "Edit record failed." });
            dispatch(
              orderAction({
                pageNo: 1,
                reloadFlag: !state.order.reloadFlag,
                itemID: null,
              })
            );
          });
      },
      onCancel() {},
    });
  }

  return (
    <>
      <AddOrderItem
        modalVisiable={modalVisiable}
        setModalVisiable={setModalVisiable}
        data={data}
      />
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: 800,
          marginBottom: 10,
        }}
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <h1>訂單:{state.order.order_no}</h1>
          </div>{" "}
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            訂單狀態:{" "}
            {data.finished ? (
              <div style={{ display: "inline-block", color: "green" }}>
                完成{" "}
              </div>
            ) : (
              <div style={{ display: "inline-block", color: "red" }}>
                未完成{" "}
                <Popconfirm
                  title="你確定要刪除這張訂單嗎?"
                  visible={delConfirmVisiable}
                  onConfirm={deleteOrder}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={() => setDelConfirmVisiable(false)}
                >
                  <Button
                    type="primary"
                    danger
                    size="small"
                    onClick={() => setDelConfirmVisiable(true)}
                  >
                    刪除
                  </Button>{" "}
                </Popconfirm>
              </div>
            )}
          </div>{" "}
        </div>
      </div>

      <List
        style={data.finished ? {} : { maxHeight: 390, overflow: "scroll" }}
        size="large"
        bordered
        dataSource={data.items}
        renderItem={(item: any) => (
          <List.Item>
            <div
              style={{
                display: "table",
                width: "100%",
                padding: 0,
                fontSize: "13pt",
              }}
            >
              <div style={{ display: "table-cell", width: "50%" }}>
                {item.CODE}
                <br />
                {item.CBDESC}
                <br />
                {item.SHOP_GOODS_LOC ? (
                  <span style={{ color: "brown" }}>
                    {"貨架:" + item.SHOP_GOODS_LOC}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "table-cell",
                  width: "25%",
                  textAlign: "right",
                }}
              >
                {item.QTY} {item.UNIT}
              </div>
              <div
                style={{
                  display: "table-cell",
                  width: "25%",
                  textAlign: "right",
                }}
              >
                ${item.TOTAL}
              </div>
              <div style={{ display: "table-cell" }}>
                {data.finished ? (
                  ""
                ) : (
                  <Button
                    type="link"
                    onClick={() => {
                      dispatch(orderAction({ itemID: item.id }));
                      setModalVisiable(true);
                    }}
                  >
                    修改
                  </Button>
                )}
                <br />{" "}
                {data.finished ? (
                  ""
                ) : (
                  <Button
                    style={{ paddingLeft: 15 }}
                    type="link"
                    danger
                    size="small"
                    onClick={() => delteItem(item.id, item.CBDESC)}
                  >
                    刪除
                  </Button>
                )}
              </div>{" "}
            </div>
          </List.Item>
        )}
      />
      <div style={{ display: "table", width: "100%", marginTop: 10 }}>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "left",
              width: "50%",
              paddingLeft: 25,
              fontWeight: "bold",
            }}
          >
            產品項目: {data.items.length} 款
          </div>

          <div
            style={{
              display: "table-cell",
              textAlign: "right",
              paddingRight: 25,
              fontWeight: "bold",
            }}
          >
            總金額:{" "}
            <span style={{ color: "blue" }}>
              {" $"} {data.total}
            </span>
          </div>
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "left",
               paddingLeft: 25,
              
             
            }}
          >
            員工: {state.login_state.user}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "table",
          width: "100%",
          position: "fixed",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          >
            <Button
              className="no_print"
              type="primary"
              size="large"
              onClick={() => {
                dispatch(orderAction({ currentID: null, order_no: "" }));
                history.push("/main");
              }}
            >
              {data.finished ? "關閉" : "暫存"}
            </Button>
            {data.finished && (
              <Button
                type="dashed"
                danger
                size="large"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  window.print();
                }}
                className="no_print"
              >
                列印
              </Button>
            )}
          </div>
          {data.finished ? (
            ""
          ) : (
            <div
              style={{
                display: "table-cell",
                textAlign: "center",
                padding: 10,
                height: 80,
              }}
            >
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  setModalVisiable(true);
                }}
              >
                加入產品
              </Button>
            </div>
          )}
          {data.finished ? (
            ""
          ) : (
            <div
              style={{
                display: "table-cell",
                textAlign: "center",
                padding: 10,
                height: 70,
              }}
            >
              <Button
                disabled={finishBtnLoading}
                type="primary"
                size="large"
                onClick={() => {
                  setFinishBtnLoading(true);
                  finishOrder();
                }}
              >
                完成
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
