/* eslint-disable import/no-anonymous-default-export */
import React  from "react";
import { Button, List } from "antd";

export default function UserList(props: any) {



  return (
    <div
      style={{
        display: "table",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <div style={{ display: "table-row" }}>
        <div style={{ display: "table-cell", textAlign: "center" }}>
          <List
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 6,
            }}
            size="large"
            bordered
            dataSource={props.data}
            renderItem={(item: any) => (
              <List.Item>
                <div style={{ display: "table",width:'100%' }}>
                  <div style={{ display: "table-cell",width:'50%',textAlign:'left' }}>
                    {item.user_name}
                  </div>{" "}
                  <div style={{ display: "table-cell",textAlign:'right' }}>
                    <Button type='dashed' onClick={()=>{props.setUser({...item,type:'edit'});props.setUserModalVisiable(true)}}>修改</Button>
                  </div>{" "}
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
}
