import React, { useEffect, useState } from "react";
import { Button, List, Modal, Popconfirm, Switch } from "antd";
import AddOrderItem from "./AddOrderItem";
import { useStateContext } from "../../Store/StateProvider";
import { useHistory } from "react-router-dom";
import { getDataForTable, SearchParamsInterface } from "../../Utils/getDataForTable";
import { poAction } from "../../Reducers/po_reducer";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { genDatetimeFormat } from "../../Utils/genDateTimeFormat";

export default function PoDetail(props: any) {
  let [modalVisiable, setModalVisiable] = useState(false);
  let [state, dispatch] = useStateContext();
  let history = useHistory();
  let [data, setData] = useState({ total: 0, order_date: '', items: [], finished: false, user: { user_name: '' } });
  let [, setPageTotal] = useState();
  let [finishBtnLoading, setFinishBtnLoading] = useState(false);
  let [delConfirmVisiable, setDelConfirmVisiable] = useState(false);
  let [delItemConfirmVisiable, setItemDelConfirmVisiable] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
  ] = Object.values(state.po);

  useEffect(() => {
    getDataForTable(
      {
        pageSize,
        pageNo,
        searchField: "id",
        searchContent: state.po.currentID,
        between,
        conditionType,
        queryType: '=',
        orderBy,
        sortBy,
      } as SearchParamsInterface,
      setData,
      setPageTotal,
      "/api/po",
      "GET"
    );

    // return () => dispatch(orderAction({ currentID: null }));
  }, [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    state.po.reloadFlag,
  ]);

  function finishOrder() {
    axios
      .put("/api/po", { id: state.po.currentID, finished: true })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            //Modal.info({ title: "Add user success" });
            setModalVisiable(false);

            dispatch(
              poAction({
                pageNo: 1,
                reloadFlag: !state.po.reloadFlag,
                itemID: null,
              })
            );
          } else {
            Modal.error({
              title: "Edit record failed.",
              content: JSON.stringify(v.data.msg),
            });
          }
          setFinishBtnLoading(false);
        }
      })
      .catch((e) => {
        Modal.error({ title: "Edit record failed." });
        setFinishBtnLoading(false);
      });
  }

  function deleteOrder() {
    axios
      .delete("/api/po", {
        data: { id: state.po.currentID },
      })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            dispatch(
              poAction({
                pageNo: 1,
                reloadFlag: !state.po.reloadFlag,
                itemID: null,
                currentID: null,
                order_no: null,
              })
            );
            history.push("/main/po");
          } else {
            Modal.error({
              title: "Delete record failed.",
              content: JSON.stringify(v.data.msg),
            });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Delete record server error." });
      });
  }

  function delteItem(id: number, name: string) {
    Modal.confirm({
      title: "你確定要刪除以下項目嗎?",
      icon: <ExclamationCircleOutlined />,
      content: name,
      onOk() {
        let index = data.items.findIndex((i: any) => i.id === id);
        data.items.splice(index, 1);
        axios
          .put("/api/po", data)
          .then((v) => {
            if (v.status === 200) {
              if (v.data.success) {
                //Modal.info({ title: "Add user success" });
                setModalVisiable(false);

                dispatch(
                  poAction({
                    pageNo: 1,
                    reloadFlag: !state.po.reloadFlag,
                  })
                );
              } else {
                Modal.error({
                  title: "Edit record failed.",
                  content: JSON.stringify(v.data.msg),
                });
              }
            }
          })
          .catch((e) => {
            Modal.error({ title: "Edit record failed." });
          });
      },
      onCancel() { },
    });
  }

  return (
    <>
      <AddOrderItem
        modalVisiable={modalVisiable}
        setModalVisiable={setModalVisiable}
        data={data}
      />
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: 800,
          marginBottom: 10,
        }}
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <h1>採購單: {state.po.currentID} </h1>
            <h3> {data.user.user_name} ({genDatetimeFormat("-d", data.order_date)})</h3>
          </div>{" "}
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            訂單狀態:{" "}
            {data.finished ? (
              <div style={{ display: "inline-block", color: "green" }}>
                完成{" "}
              </div>
            ) : (
              <div style={{ display: "inline-block", color: "red" }}>
                未完成{" "}
                <Popconfirm
                  title="你確定要刪除這張訂單嗎?"
                  visible={delConfirmVisiable}
                  onConfirm={deleteOrder}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={() => setDelConfirmVisiable(false)}
                >
                  <Button
                    type="primary"
                    danger
                    size="small"
                    onClick={() => setDelConfirmVisiable(true)}
                  >
                    刪除
                  </Button>{" "}
                </Popconfirm>
              </div>
            )}
          </div>{" "}
        </div>
      </div>

      <List
        style={data.finished ? {} : { maxHeight: 390, overflow: "scroll" }}
        size="large"
        bordered={false}
        dataSource={data.items}
        renderItem={(item: any) => (
          <List.Item style={{ border: 0 }}>
            <div
              style={{
                display: "table",
                width: "100%",
                padding: 0,
                fontSize: "13pt",
                borderBottom: "1px solid  gray",
              }}
            >
              <div style={{ display: "table-cell", width: "60%" }}>
                {item.CODE} <span style={{ color: "white" }}>---</span>
                <span style={{ color: "red" }}>
                  {item.PRO && "安全倉數: " + item.PRO.SAVE_STOCK}
                </span>{" "}
                <br /> {item.CBDESC}
                {/* <br />
                {item.SHOP_GOODS_LOC ? (
                  <span style={{ color: "brown" }}>
                    {"貨架:" + item.SHOP_GOODS_LOC}
                  </span>
                ) : (
                  ""
                )} */}
              </div>
              <div
                style={{
                  display: "table-cell",
                  width: "25%",
                  textAlign: "right",
                }}
              >
                {item.QTY} {item.UNIT}
                <br /> {item.PACKING}
              </div>

              {// disable if user_no_price group:
                state.login_state.group != "user_no_price" &&
                <div
                  style={{
                    display: "table-cell",
                    width: "25%",
                    textAlign: "right",
                  }}
                >
                  ${item.RETAILUP}
                </div>

              }
              <div style={{ display: "table-cell" }}>
                {data.finished ? (
                  ""
                ) : (
                  <Button
                    type="link"
                    onClick={() => {
                      dispatch(poAction({ itemID: item.id }));
                      setModalVisiable(true);
                    }}
                  >
                    修改
                  </Button>
                )}
                <br />{" "}
                {data.finished ? (
                  ""
                ) : (
                  <Button
                    style={{ paddingLeft: 15 }}
                    type="link"
                    danger
                    size="small"
                    onClick={() => delteItem(item.id, item.CBDESC)}
                  >
                    刪除
                  </Button>
                )}
              </div>{" "}
            </div>
          </List.Item>
        )}
      />
      <div style={{ display: "table", width: "100%", marginTop: 10 }}>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "left",
              width: "50%",
              paddingLeft: 25,
              fontWeight: "bold",
            }}
          >
            產品項目: {data.items.length} 款
          </div>

          {// disable if user_no_price group:
            state.login_state.group != "user_no_price" &&
            <div
              style={{
                display: "table-cell",
                textAlign: "right",
                paddingRight: 25,
                fontWeight: "bold",
              }}
            >
              總金額:{" "}
              <span style={{ color: "blue" }}>
                {" $"} {data.total}
              </span>
            </div>

          }

        </div>
      </div>
      <div
        style={{
          display: "table",
          width: "100%",
          position: "fixed",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          >
            <Button
              className="no_print"
              type="primary"
              size="large"
              onClick={() => {
                dispatch(poAction({ currentID: null, order_no: "" }));
                history.push("/main/po");
              }}
            >
              {data.finished ? "關閉" : "暫存"}
            </Button>
            {data.finished && (
              <>
                {" "}
                <Button
                  danger
                  className="no_print"
                  type="primary"
                  size="large"
                  onClick={() => {
                    axios
                      .put("/api/po", {
                        id: state.po.currentID,
                        finished: false,
                        printed: false,
                      })
                      .then(() =>
                        dispatch(poAction({ reloadFlag: !state.po.reloadFlag }))
                      );
                  }}
                >
                  修改
                </Button>
                <Button
                  type="dashed"
                  danger
                  size="large"
                  style={{ marginLeft: 16 }}
                  onClick={() => {
                    history.push("/poprint");
                  }}
                  className="no_print"
                >
                  列印
                </Button>
                <br />
                <Switch
                  checked={state.po.print_show_price}
                  unCheckedChildren="列印不顯示價格"
                  checkedChildren="列印顯示價格"
                  onChange={() => {
                    console.log(state.po.print_show_price);
                    dispatch(
                      poAction({ print_show_price: !state.po.print_show_price })
                    );
                  }}
                ></Switch>{" "}
                <Switch
                  checked={state.po.print_show_stock}
                  unCheckedChildren="列印不顯示倉數"
                  checkedChildren="列印顯示倉數"
                  onChange={() => {
                    console.log(state.po.print_show_stock);
                    dispatch(
                      poAction({ print_show_stock: !state.po.print_show_stock })
                    );
                  }}
                ></Switch>
              </>
            )}
          </div>
          {data.finished ? (
            ""
          ) : (
            <div
              style={{
                display: "table-cell",
                textAlign: "center",
                padding: 10,
                height: 80,
              }}
            >
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  setModalVisiable(true);
                }}
              >
                加入產品
              </Button>
            </div>
          )}
          {data.finished ? (
            ""
          ) : (
            <div
              style={{
                display: "table-cell",
                textAlign: "center",
                padding: 10,
                height: 70,
              }}
            >
              <Button
                disabled={finishBtnLoading}
                type="primary"
                size="large"
                onClick={() => {
                  setFinishBtnLoading(true);
                  finishOrder();
                }}
              >
                完成
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
