// state.order.itemID not null that mean modify order items
// state.order.currentID null that mean add new order.
// back to order list need clear state.order.currentID and state.order.itemID and state.order.order_no

import React, { useEffect, useState } from "react";
import { Button, Switch, Slider } from "antd";
import { useStateContext } from "../../Store/StateProvider";
import AddBarcode from "./AddBarcode";
import JsBarcode from "jsbarcode";
import Modal from "antd/lib/modal/Modal";
import {useHistory} from 'react-router-dom'

export default function Barcode(props: any) {
  let [modalVisiable, setModalVisiable] = useState(false);
  let [state] = useStateContext();
  let [isBroTher, setIsBrother] = useState(false);
  let [barcodeModal, setBarcodeModal] = useState(false);
  let history=useHistory()

  let defaultBarConfig = {
    proNameVisiable: true,
    proNameSize: 17,
    proPackingVisiable: true,
    proPackingSize: 17,
    proPriceVisiable: true,
    proPriceSize: 19,
    barSize: 2,
    barcodeVisiable: true,
    barcodeSize: 20,
    mobileLeft:45
  };
  if (localStorage.getItem("BarCodeDisplayConfig") === null) {
    localStorage.setItem(
      "BarCodeDisplayConfig",
      JSON.stringify(defaultBarConfig)
    );
  }
  function setBarConfig(key: string, value: string | number | Boolean) {
    let barConfig = JSON.parse(
      localStorage.getItem("BarCodeDisplayConfig") ||
        JSON.stringify(defaultBarConfig)
    );
    barConfig[key] = value;
    localStorage.setItem("BarCodeDisplayConfig", JSON.stringify(barConfig));
  }

  let barConfig = JSON.parse(
    localStorage.getItem("BarCodeDisplayConfig") ||
      JSON.stringify(defaultBarConfig)
  );

  // barcode setting.
  let [proNameVisiable, setProNameVisiable] = useState(
    barConfig.proNameVisiable
  );
  let [proNameSize, setProNameSize] = useState(barConfig.proNameSize);
  let [proPackingVisiable, setProPackingVisiable] = useState(
    barConfig.proPackingVisiable
  );
  let [proPackingSize, setProPackingSize] = useState(barConfig.proPackingSize);
  let [proPriceVisiable, setProPriceVisiable] = useState(
    barConfig.proPriceVisiable
  );
  let [proPriceSize, setProPriceSize] = useState(barConfig.proPriceSize);
  let [barSize, setBarSize] = useState(barConfig.barSize); // barcode width 2 x height 100
  let [barcodeVisiable, setBarcodeVisiable] = useState(
    barConfig.barcodeVisiable
  );
  let [barcodeSize, setBarcodeSize] = useState(barConfig.barcodeSize);
  let [mobileLeft, setMobileLeft] = useState(barConfig.mobileLeft);


  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    //const canvas = document.createElement("canvas");

    //    (ref as any).current.innerHTML = "<img id='canvas' alt=''/>";
    (ref as any).current.innerHTML = '<svg id="canvas"></svg>';
    JsBarcode(
      "#canvas",
      state.barcode.CODE,
      isBroTher
        ? { width: 1, height: 38, fontSize: 13 }
        : {
            fontSize: barcodeSize,
            displayValue: barcodeVisiable,
            width: barSize,
            height: barSize * 50,
          }
    );
  }, [state.barcode.CODE, isBroTher, barcodeSize, barcodeVisiable, barSize]);

  return (
    <>
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: 800,
        }}
      >
        <div style={{ display: "table-row" }} className="no_print">
          <div
            style={{ display: "table-cell", textAlign: "center" }}
            className="no_print"
          >
            <h1 className="no_print">產品條碼</h1>
          </div>
        </div>
      </div>
      <div
        style={
          isBroTher
            ? {
                display: "table",
                width: "100%",
                height: "100%",
                maxHeight: "100%",
                transform: "rotate(270deg)",
                position: "absolute",
                left: mobileLeft, // default 45
              }
            : {
                display: "table",
                width: "100%",
                height: "100%",
                maxHeight: "100%",
              }
        }
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <div style={{ display: "table", width: "100%" }}>
              <div style={{ display: "table-row" }} ref={ref}></div>

              <div
                style={
                  isBroTher
                    ? { display: "table-row", fontSize: `${proNameSize - 8}pt` }
                    : { display: "table-row", fontSize: `${proNameSize}pt` }
                }
              >
                {proNameVisiable && state.barcode.CBDESC}
              </div>
              <div
                style={
                  isBroTher
                    ? {
                        display: "table-row",
                        fontSize: `${proPackingSize - 8}pt`,
                      }
                    : { display: "table-row", fontSize: `${proPackingSize}pt` }
                }
              >
                {proPackingVisiable && state.barcode.PACKING}
              </div>
              <div
                style={
                  isBroTher
                    ? {
                        display: "table-row",
                        fontSize: `${proPriceSize - 6}pt`,
                      }
                    : { display: "table-row", fontSize: `${proPriceSize}pt` }
                }
              >
                {proPriceVisiable &&
                  state.barcode.PRICE &&
                  state.barcode.PRICE.slice(0, -2)}
              </div>
            </div>
          </div>
          {isBroTher && (
            <div style={{ display: "table-cell", textAlign: "center" }}>.</div>
          )}
        </div>
      </div>

      <AddBarcode
        modalVisiable={modalVisiable}
        setModalVisiable={setModalVisiable}
        setIsBrother={setIsBrother}
      />
      <div
        style={{
          display: "table",
          width: "100%",
          position: "fixed",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          >
            <Button
              className="no_print"
              onClick={() => {
                setBarcodeModal(true);
                setIsBrother(false);
              }}
            >
              條碼設定
            </Button>
            {/* Barcode setting setting modal. */}
            <Modal
              visible={barcodeModal}
              closable
              destroyOnClose
              onOk={() => {
                setBarcodeModal(false);
              }}
              onCancel={() => {
                setBarcodeModal(false);
              }}
              title="條碼顯示設定"
              style={{ top: 350 }}
              footer={[
                <Button
                 danger
                  key="back"
                  onClick={() => {
                    
                    localStorage.setItem(
                      "BarCodeDisplayConfig",
                      JSON.stringify(defaultBarConfig)
                    );
                    history.go(0)
                  }}
                >
                  恢復默認設定
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => setBarcodeModal(false)}
                >
                  確定
                </Button>,
              ]}
            >
              <div style={{ display: "table", width: "100%" }}>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "30%",
                      verticalAlign: "middle",
                    }}
                  >
                    條碼大小
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "20%",
                      verticalAlign: "middle",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "50%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Slider
                      min={0}
                      max={2}
                      step={0.5}
                      defaultValue={barSize}
                      value={barSize}
                      onChange={(v: any) => {
                        setBarSize(v);
                        setBarConfig("barSize", v);
                      }}
                    ></Slider>
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "30%",
                      verticalAlign: "middle",
                    }}
                  >
                    條碼字型
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "20%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Switch
                      defaultChecked={barcodeVisiable}
                      checkedChildren="顯示"
                      unCheckedChildren="隱藏"
                      onChange={() => {
                        setBarcodeVisiable(!barcodeVisiable);
                        setBarConfig("barcodeVisiable", !barcodeVisiable);
                      }}
                    ></Switch>
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "50%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Slider
                      min={20}
                      max={70}
                      step={1}
                      defaultValue={20}
                      value={barcodeSize}
                      onChange={(v: any) => {
                        setBarcodeSize(v);
                        setBarConfig("barcodeSize", v);
                      }}
                    ></Slider>
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "30%",
                      verticalAlign: "middle",
                    }}
                  >
                    產品名稱
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "20%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Switch
                      defaultChecked={proNameVisiable}
                      checkedChildren="顯示"
                      unCheckedChildren="隱藏"
                      onChange={() => {
                        setProNameVisiable(!proNameVisiable);
                        setBarConfig("proNameVisiable", !proNameVisiable);
                      }}
                    ></Switch>
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "50%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Slider
                      min={13}
                      max={30}
                      step={1}
                      defaultValue={17}
                      value={proNameSize}
                      onChange={(v: any) => {
                        setProNameSize(v);
                        setBarConfig("proNameSize", v);
                      }}
                    ></Slider>
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "30%",
                      verticalAlign: "middle",
                    }}
                  >
                    產品包裝
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "20%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Switch
                      defaultChecked={proPackingVisiable}
                      checkedChildren="顯示"
                      unCheckedChildren="隱藏"
                      onChange={() => {
                        setProPackingVisiable(!proPackingVisiable);
                        setBarConfig("proPackingVisiable", !proPackingVisiable);
                      }}
                    ></Switch>
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "50%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Slider
                      min={13}
                      max={30}
                      step={1}
                      defaultValue={17}
                      value={proPackingSize}
                      onChange={(v: any) => {
                        setProPackingSize(v);
                        setBarConfig("proPackingSize", v);
                      }}
                    ></Slider>
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "30%",
                      verticalAlign: "middle",
                    }}
                  >
                    產品價格
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "20%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Switch
                      defaultChecked={proPriceVisiable}
                      checkedChildren="顯示"
                      unCheckedChildren="隱藏"
                      onChange={() => {
                        setProPriceVisiable(!proPriceVisiable);
                        setBarConfig("proPriceVisiable", !proPriceVisiable);
                      }}
                    ></Switch>
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "50%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Slider
                      min={13}
                      max={30}
                      step={1}
                      defaultValue={19}
                      value={proPriceSize}
                      onChange={(v: any) => {
                        setProPriceSize(v);
                        setBarConfig("proPriceSize", v);
                      }}
                    ></Slider>
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "30%",
                      verticalAlign: "middle",
                    }}
                  >
                    手機位置
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "20%",
                      verticalAlign: "middle",
                    }}
                  >

                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 2,
                      width: "50%",
                      verticalAlign: "middle",
                    }}
                  >
                    <Slider
                      min={40}
                      max={100}
                      step={1}
                      defaultValue={45}
                      value={mobileLeft}
                      onChange={(v: any) => {
                        setMobileLeft(v);
                        setBarConfig("mobileLeft", v);
                      }}
                    ></Slider>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          >
            <Button
              className="no_print"
              type="primary"
              size="large"
              onClick={() => {
                setModalVisiable(true);
              }}
            >
              輸入編號
            </Button>{" "}
            {state.barcode.CODE && (
              <Button
                className="no_print"
                type="primary"
                size="large"
                onClick={() => {
                  setIsBrother(false);
                  setTimeout(() => {
                    window.print();
                  }, 1000);
                }}
              >
                電腦列印
              </Button>
            )}{" "}
            {state.barcode.CODE && (
              <Button
                className="no_print"
                type="primary"
                size="large"
                onClick={() => {
                  setIsBrother(true);
                  setTimeout(() => {
                    window.print();
                  }, 1000);
                }}
              >
                手機列印
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
