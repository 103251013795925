export function genDatetimeFormat(
  format?: "-" | "m" | "d" | "-d",
  time?: string
): string {
  // return now time format, create by Daniel 2020
  // parameter() out put format: 20190705124850
  // parameter('-') out put format: 2019-08-18 11:18:30
  // parameter('m') out put format: 01-jan-2019
  // parameter('d') out put format: 20190705
  // parameter('-d') out put format: 2019-07-05

  // parameter(,time) 2020-04-27T08:30:58.000Z will convert to localtime. if dont provided default to Now.

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date: Date;
  if (time) {
    date = new Date(time);
  } else {
    date = new Date();
  }

  let yyyy = date.getFullYear();
  let MM = date.getMonth() + 1;
  let dd = date.getDate();
  let hh = date.getHours();
  let mm = date.getMinutes();
  let sss = date.getSeconds();
  let MM_s: string = MM < 10 ? "0" + MM.toString() : MM.toString();
  let dd_s: string = dd < 10 ? "0" + dd.toString() : dd.toString();
  let hh_s: string = hh < 10 ? "0" + hh.toString() : hh.toString();
  let mm_s: string = mm < 10 ? "0" + mm.toString() : mm.toString();
  let sss_s: string = sss < 10 ? "0" + sss.toString() : sss.toString();

  switch (format) {
    case "-":
      return (
        yyyy + "-" + MM_s + "-" + dd_s + " " + hh_s + ":" + mm_s + ":" + sss_s
      );
    case "m":
      return dd_s + "-" + monthNames[MM] + "-" + yyyy;
    case "d":
      return yyyy + MM_s + dd_s;
    case "-d":
      return yyyy + "-" + MM_s + "-" + dd_s;
    default:
      return yyyy + MM_s + dd_s + hh_s + mm_s + sss_s;
  }

  //   if (format === "-") {
  //     return (
  //       yyyy + "-" + MM_s + "-" + dd_s + " " + hh_s + ":" + mm_s + ":" + sss_s
  //     );
  //   } else if (format === "m") {
  //     return dd_s + "-" + monthNames[MM] + "-" + yyyy;
  //   } else {
  //     return yyyy + MM_s + dd_s + hh_s + mm_s + sss_s;
  //   }
}
