import { defaultStateType } from "../Store/defaultStatus";

export function reducer(state: any, action: any) {
  console.log('this is poReducer!')

  switch (action.type) {
    case "PO":
      return { ...state, ...action.playload };
    default:
      return state;
  }
}

export function poAction(object: defaultStateType) {
  return { type: "PO", playload: object };
}
