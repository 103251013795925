// state.po.itemID not null that mean modify order items
// state.po.currentID null that mean add new order.
// back to order list need clear state.po.currentID and state.po.itemID and state.po.order_no

import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Upload,
  message,
} from "antd";

import axios from "axios";
import { useStateContext } from "../../Store/StateProvider";
import { poAction } from "../../Reducers/po_reducer";
import { useHistory } from "react-router-dom";
import {
  CameraOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { scanBarcode } from "../../Utils/barCodeScanner";

export default function AddOrderItem(props: any) {
  const [form] = Form.useForm();
  let history = useHistory();
  let [pro, setPro] = useState(null);
  let [stock, setStock] = useState(null);
  let [addbtnLoading, setAddBtnLoading] = useState(true);
  let [state, dispatch] = useStateContext();
  let data = props.data;
  let modalVisiable = props.modalVisiable;
  let setModalVisiable = props.setModalVisiable;
  const inputRef = useRef<any>(null);

  let [sltCode, setSltCOde] = useState([]); // for code load.

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current!.focus({
          cursor: "start",
        });
      }
    }, 100);
  }, [modalVisiable]);

  useEffect(() => {
    setTimeout(() => console.log("adOrderItem first"), 2);
  }, []);

  useEffect(() => {
    if (state.po.itemID) {
      console.log("setForm:", state.po.itemID);
      let p = data.items.filter((i: any) => i.id === state.po.itemID)[0];

      // fast render content.
      setAddBtnLoading(true);
      form.setFieldsValue({
        CODE: p.CODE,
        CBDESC: p.CBDESC,
        QTY: p.QTY,
        UNIT: p.UNIT,
        PACKING: p.PACKING,
        RATIO_D: p.RATIO_D,
        RATIO_N: p.RATIO_N,
        ToTAL: p.TOTAL
      });
      getProInfo(p.CODE,
        () =>
          form.setFieldsValue({
            CODE: p.CODE,
            CBDESC: p.CBDESC,
            QTY: p.QTY,
            UNIT: p.UNIT,
            PACKING: p.PACKING,

          })
      );
      getStock(p.CODE);
    }
    setTimeout(() => console.log("adOrderItem 2"), 2);
  }, [state.po.itemID]);

  function getProInfo(code: string, fn?: () => void) {
    //讀取API product信息. fn option callback function

    if (code.indexOf("`|`") > 0) {
      var code2 = code.split("`|`");
      code = code2[0];
    }

    form.setFieldsValue({
      CODE: code,
    });
    getStock(code);
    axios("https://kfkproapi.kfkhk.com/api/goods/" + code, {
      method: "get",
      params: { abc: "34sfsdf34234sfsds34fsfe34f" },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data[0];
          if (playload) {
            form.setFieldsValue({
              CODE: playload.CODE,
              CBDESC: playload.CBDESC,
              QTY: "",
              PACKING: "",
              //UNIT:playload?.price[0].UNIT
            });
            setPro(playload);

            if (fn) {
              fn();
            }
            setAddBtnLoading(false);
          } else {
            message.warn("無資料!");
          }
        } else {
          message.warn("Server故障!");
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function getStock(code: string) {
    //讀取產品庫倉(本地資料庫).

    axios("/api/products", {
      method: "get",
      params: { searchField: "CODE", searchContent: code },
    })
      .then((v) => {
        if (v.status === 200 && v.data.data[0].length > 0) {
          let d = v.data.data[0][0];
          setStock(d);
          form.setFieldsValue({ SHOP_GOODS_LOC: d.SHOP_GOODS_LOC });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function genRetaiPriceList(pro: any) {
    // show 零售價格格式字符

    let str = "";
    pro.price
      .filter((e: any) => {
        return e.RETAILUNIT === 1;
      })
      .forEach((e: any) => {
        str += "$" + e.RETAILUP + "/" + e.UNIT + " , ";
      });
    return str;
  }

  function genStockUnitAndPacking(pro: any) {
    // show 安全倉數格式字符

    let str = "";
    pro.price
      .filter((e: any) => {
        return e.STOCKUNIT === 1;
      })
      .forEach((e: any) => {
        str += e.UNIT + " (" + e.PACKING + ") ";
      });
    return pro.safestock.ROL + str;
  }

  function updateOrAddOrder(v: any) {
    if (state.po.currentID === null) {
      // add new

      setAddBtnLoading(true);
      axios
        .post("/api/po", {
          CODE: v.CODE,
          total: v.RETAILUP * v.QTY,
          items: [
            {
              CODE: v.CODE,
              CBDESC: v.CBDESC,
              QTY: v.QTY,
              UNIT: v.UNIT,
              PACKING: v.PACKING,
              RATIO_N: v.RATIO_N,
              RATIO_D: v.RATIO_D,
              RETAILUP: v.RETAILUP,
              TOTAL: v.RETAILUP * v.QTY,
              SHOP_GOODS_LOC: v.SHOP_GOODS_LOC,
            },
          ],
        })
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              setModalVisiable(false);
              form.resetFields();
              dispatch(
                poAction({
                  pageNo: 1,
                  reloadFlag: !state.po.reloadFlag,
                  itemID: null,
                  currentID: v.data.data.id,
                  order_no: v.data.data.order_no,
                })
              );
              history.push("/main/podetail");
            } else {
              Modal.error({
                title: "Add record failed.",
                content: JSON.stringify(v.data.msg),
              });

              setAddBtnLoading(false);
            }
          }
        })
        .catch((e) => {
          Modal.error({ title: "Add user failed." });
          console.log(e);
          setAddBtnLoading(false);
        });
    } else if (state.po.currentID !== null) {
      // modify exist order.

      if (state.po.itemID === null) {
        // add new item.
        data.items.push({
          CODE: v.CODE,
          CBDESC: v.CBDESC,
          QTY: v.QTY,
          UNIT: v.UNIT,
          PACKING: v.PACKING,
          RATIO_N: v.RATIO_N,
          RATIO_D: v.RATIO_D,
          RETAILUP: v.RETAILUP,
          TOTAL: v.RETAILUP * v.QTY,
          SHOP_GOODS_LOC: v.SHOP_GOODS_LOC,
        });
      } else {
        // modify exsit item.
        data.items[data.items.findIndex((i: any) => i.id === state.po.itemID)] =
        {
          id: state.po.itemID,
          CODE: v.CODE,
          CBDESC: v.CBDESC,
          QTY: v.QTY,
          UNIT: v.UNIT,
          PACKING: v.PACKING,
          RATIO_N: v.RATIO_N,
          RATIO_D: v.RATIO_D,
          RETAILUP: v.RETAILUP,
          TOTAL: v.RETAILUP * v.QTY,
          SHOP_GOODS_LOC: v.SHOP_GOODS_LOC,
        };
      }

      // calculate total
      let t: number = 0;
      data.items.map((i: any) => {
        t = i.TOTAL + t;
      });
      data.total = t;

      setAddBtnLoading(true);
      axios
        .put("/api/po", data)
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              //Modal.info({ title: "Add user success" });
              setModalVisiable(false);
              form.resetFields();
              dispatch(
                poAction({
                  pageNo: 1,
                  reloadFlag: !state.po.reloadFlag,
                  itemID: null,
                })
              );
            } else {
              Modal.error({
                title: "Edit record failed.",
                content: JSON.stringify(v.data.msg),
              });
            }
            setAddBtnLoading(false);
          }
        })
        .catch((e) => {
          Modal.error({ title: "Edit record failed." });
          setAddBtnLoading(false);
        });
    }
  }

  function loadProCode(code: string) {
    if (code.trim() === "") return;
    code = code.toUpperCase();
    axios("https://kfkproapi.kfkhk.com/api/goodsmulti/" + code, {
      method: "get",
      params: { abc: "34sfsdf34234sfsds34fsfe34f" },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data;
          if (playload) {
            setSltCOde(playload);
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  return (
    <>
      <Modal
        visible={modalVisiable}
        onCancel={() => {
          setModalVisiable(false);
          form.resetFields();
          setPro(null);
          setStock(null);
          dispatch(poAction({ itemID: null }));
          setAddBtnLoading(true);
        }}
        footer={false}
        destroyOnClose
        style={{ top: 5 }}
      >
        <div
          style={{
            display: "table",
            width: "100%",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <h1>
                {state.po.currentID !== null && state.po.itemID !== null
                  ? "採購修改產品"
                  : "採購加入產品"}
              </h1>
            </div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              {state.po.currentID === null
                ? "新訂單"
                : "當前訂單:" + state.po.order_no}
            </div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <Form
                size="small"
                name="basic"
                style={{ marginLeft: 20, marginRight: 20 }}
                // initialValues={{  }}
                onFinish={updateOrAddOrder}
                labelCol={{ span: 3 }}
                form={form}
              >
                <Form.Item name="id" label="id" style={{ display: "none" }}>
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="RATIO_N"
                  label="RATIO_N"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="RATIO_D"
                  label="RATIO_D"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="PACKING"
                  label="PACKING"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="RETAILUP"
                  label="RETAILUP"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="SHOP_GOODS_LOC"
                  label="SHOP_GOODS_LOC"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  label="產品編號"
                  name="CODE"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  {/* <Input
                    allowClear
                    size="large"
                    onClick={() => {
                      if (navigator.clipboard) {
                        navigator.clipboard
                          .readText()
                          .then((text) => {
                            if (text) {
                              getProInfo(text);
                            }
                          })
                          .catch((e) => console.log(e));
                      }
                    }}
                  /> */}
                  <Select
                    ref={inputRef}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 280,
                    }}
                    onSearch={loadProCode}
                    showSearch
                    onChange={(vl: string) => getProInfo(vl)}
                    size="large"
                    allowClear
                  // onClick={() => {
                  //   if (navigator.clipboard) {
                  //     navigator.clipboard
                  //       .readText()
                  //       .then((text) => {
                  //         if (text) {
                  //           // loadProCode(text);
                  //           getProInfo(text);
                  //         }
                  //       })
                  //       .catch((e) => console.log(e));
                  //   }
                  // }}
                  >
                    {sltCode.map((item) => (
                      <Select.Option
                        value={item["CODE"] + "`|`" + item["CBDESC"]}
                        key={item["CODE"]}
                      >
                        {item["CODE"] + " " + item["CBDESC"]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="產品名稱"
                  name="CBDESC"
                  rules={[{ required: true, min: 1 }]}
                  hasFeedback
                >
                  <Input
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 280,
                    }}
                    size="large"
                    readOnly
                  ></Input>
                </Form.Item>
                <Form.Item label="包装" name="PACKING" hasFeedback>
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="單位"
                  name="UNIT"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <Select
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 280,
                    }}
                    onChange={(v) =>
                      (pro as any).price
                        .filter((p: any) => p.UNIT === v)
                        .map((m: any) => {
                          form.setFieldsValue({
                            RETAILUP: m.RETAILUP,
                            RATIO_N: m.RATIO_N,
                            RATIO_D: m.RATIO_D,
                          });
                          return (
                            <Select.Option key={m.UNIT} value={m.UNIT}>
                              {m.UNIT}
                            </Select.Option>
                          );
                        })
                    }
                  >
                    {pro === null
                      ? ""
                      : (pro as any).price
                        .filter((p: any) => p.EXPIRED === 0)
                        .map((m: any, index: number) => {
                          if (m.PACKING.trim()) {
                            form.setFieldsValue({ PACKING: m.PACKING });
                          }
                          if (index === 0) {
                            form.setFieldsValue({
                              UNIT: m.UNIT,
                              RETAILUP: m.RETAILUP,
                              RATIO_N: m.RATIO_N,
                              RATIO_D: m.RATIO_D,
                            });
                          }

                          if (data && (data.items.filter((i: any) => i.id === state.po.itemID)[0]?.UNIT === m.UNIT)) {
                            form.setFieldsValue({
                              UNIT: m.UNIT,
                              RETAILUP: m.RETAILUP,
                              RATIO_N: m.RATIO_N,
                              RATIO_D: m.RATIO_D,
                            });
                          }


                          if (m.ISINVUNIT === 1) {

                            return (
                              <Select.Option key={m.UNIT} value={m.UNIT}>
                                {m.UNIT}
                              </Select.Option>
                            );
                          }
                        })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="訂購數量"
                  name="QTY"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <InputNumber size="large" onChange={(v) => { if (v <= 0) { form.setFieldsValue({ QTY: 1 }) } }} />
                </Form.Item>
              </Form>
              <div
                style={{
                  display: "table",
                  marginLeft: 20,
                  marginBottom: 20,
                  padding: 3,
                }}
              >
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 10,
                      fontSize: "14pt",
                    }}
                  >
                    貨架與倉數:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 10,
                      fontSize: "14pt",
                    }}
                  >
                    {stock === null
                      ? ""
                      : (stock as any).SHOP_GOODS_LOC +
                      ", " +
                      (stock as any).SHOP_STOCK +
                      (stock as any).UNIT}
                  </div>
                </div>

                {// disable if user_no_price group:
                  state.login_state.group != "user_no_price" &&

                  <div style={{ display: "table-row" }}>
                    <div style={{ display: "table-cell", fontSize: "14pt" }}>
                      零售價格:{" "}
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        fontSize: "14pt",
                        color: "blue",
                      }}
                    >
                      {pro === null ? "" : genRetaiPriceList(pro)}
                    </div>
                  </div>
                }

                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    安全倉數:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    {pro === null ? "" : genStockUnitAndPacking(pro)}
                  </div>
                </div>
              </div>

              <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                loading={addbtnLoading}
                onClick={() => form.submit()}
              >
                {state.po.currentID !== null && state.po.itemID !== null
                  ? "修改"
                  : "加入"}
              </Button>
              <Button
                style={{ marginRight: 16 }}
                size="large"
                onClick={() => {
                  let vl: { CODE: "" } = form.getFieldsValue();
                  if (vl.CODE) {
                    getProInfo(vl.CODE);
                    // getStock(vl.CODE);
                  } else {
                    getProInfo("PD018-6050B");
                    //  getStock("PD018-6050B");
                  }
                }}
              >
                查找
              </Button>
              <Upload
                accept="image/*;capture=camera"
                multiple={false}
                showUploadList={false}
                beforeUpload={(file: any, FileList: any[]) => {
                  // console.log(file);
                  // console.log(FileList);
                  const reader = new FileReader();

                  reader.onload = (e: any) => {
                    scanBarcode(e.target.result, getProInfo);
                  };
                  reader.readAsDataURL(file);

                  // scanBarcode(file)
                  return false;
                }}
              >
                <Button size="large" icon={<CameraOutlined />}>
                  相機
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
