// state.order.itemID not null that mean modify order items
// state.order.currentID null that mean add new order.
// back to order list need clear state.order.currentID and state.order.itemID and state.order.order_no

import React, { useEffect, useState } from "react";
import { Button} from "antd";
import AddOrderItem from "./AddOrderItem";
import ListOrder from "./ListOrder";
import { useStateContext } from "../../Store/StateProvider";
import { orderAction } from "../../Reducers/order_reducer";

export default function Order(props: any) {
  let [modalVisiable, setModalVisiable] = useState(false);  
  let [,dispatch]=useStateContext()

  useEffect(()=>{
        dispatch(orderAction({currentID:null}))
  },[])

  return (
    <>
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: 800
        }}
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell",textAlign:'center' }}><h2>訂單列表</h2></div>        
        </div>
      </div>
      <ListOrder />
      <AddOrderItem modalVisiable={modalVisiable} setModalVisiable={setModalVisiable}/>
      <div
        style={{
          display: "table",
          width: "100%",
          position: "fixed",
          bottom: 0,
          maxWidth: 800
        }}
      >
        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 70,
            }}
          >
            <Button type="primary" size="large" onClick={()=>{setModalVisiable(true)}}>
              加入新訂單
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
