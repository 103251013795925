export interface defaultStateType {
  login_state?: { state: string; user: string; group: string };

  product?: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [string, string, string] | null; //[between field,time string 1, time string 1]
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID: number | null;
    reloadFlag?: boolean;
  };
  order?: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [string, string, string] | null; //[between field,time string 1, time string 1]
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID?: number | null;
    itemID?: number | null;
    order_no?: number | null;
    reloadFlag?: boolean;
  };
  po?: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [string, string, string] | null; //[between field,time string 1, time string 1]
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID?: number | null;
    itemID?: number | null;
    order_no?: number | null;
    reloadFlag?: boolean;
    print_show_price?:boolean;
    print_show_stock?:boolean;
  };
  report?: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [string, string, string] | null; //[between field,time string 1, time string 1]
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    reloadFlag?: boolean;
  };
  refill?: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [string, string, string] | null; //[between field,time string 1, time string 1]
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID?: number | null;
    reloadFlag?: boolean;
  };
  barcode?: {
    CODE?: string | null;
    CBDESC?: string | null;
    PRICE?: string | null;
  };
  [propname: string]: any;
}

export const defaultState: defaultStateType = {
  login_state: { state: "loading", user: "", group: "user" },
  product: {
    pageSize: 4,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: null,
    conditionType: "and",
    queryType: "like",
    orderBy: "updated_date",
    sortBy: "DESC",
    currentID: null,
    reloadFlag: true,
  },
  order: {
    pageSize: 6,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: null,
    conditionType: "and",
    queryType: "like",
    orderBy: "finished,id",
    sortBy: "DESC",
    currentID: null,
    itemID: null,
    order_no: null,
    reloadFlag: true,
  },
  po: {
    pageSize: 6,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: null,
    conditionType: "and",
    queryType: "like",
    orderBy: "finished,id",
    sortBy: "DESC",
    currentID: null,
    itemID: null,
    order_no: null,
    reloadFlag: true,
    print_show_price:false,
    print_show_stock:true
  },
  report: {
    pageSize: 5,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: null,
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    reloadFlag: true,
  },
  refill: {
    pageSize: 6,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: null,
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    currentID: null,
    reloadFlag: true,
  },
  barcode: {
    CODE: null,
    CBDESC: null,
    PRICE: null,
  },


  test: { test1: 0, test2: 0 },
}; // checking/true/false
