/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Spin } from "antd";

export default () => {
  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        margin: "0 auto",
        textAlign: "center",
        paddingTop: "10%"
      }}
    >
      <Spin tip="Loading..." size='large'/>
    </div>
  );
};