import { checkLoginReducer } from "./login_reducer";
import { reducer as orderReducer } from "./order_reducer";
import { reducer as poReducer } from "./po_reducer";
import { reducer as productReducer } from "./product_reducer";
import { defaultStateType } from "../Store/defaultStatus";
import { reducer as reportReducer } from "./report_reducer";
import { reducer as barcodeReducer } from "./barcode_reducer";
import { reducer as refillReducer } from "./refill_reducer";


export const mainReducer = (state: defaultStateType, action: any) => {
    
  let nextState = {
    login_state: checkLoginReducer(state.login_state, action),
    order: orderReducer(state.order, action),
    po:poReducer(state.po, action),
    product: productReducer(state.product, action),
    report: reportReducer(state.report, action),
    refill:refillReducer(state.refill,action),
    barcode:barcodeReducer(state.barcode,action)
  };
  console.log(nextState);

  localStorage.setItem("state", JSON.stringify(nextState));
  return nextState;
};
