import { defaultStateType } from "../Store/defaultStatus";

export function reducer(state: any, action: any) {
  console.log('this is reportReducer!')

  switch (action.type) {
    case "REPORT":
      return { ...state, ...action.playload };
    default:
      return state;
  }
}

export function reportAction(object: defaultStateType) {
  return { type: "REPORT", playload: object };
}
