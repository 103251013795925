// state.order.itemID not null that mean modify order items
// state.order.currentID null that mean add new order.
// back to order list need clear state.order.currentID and state.order.itemID and state.order.order_no

import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form, Input, Select, Upload, message } from "antd";

import axios from "axios";
import { useStateContext } from "../../Store/StateProvider";
import { useHistory } from "react-router-dom";
import { productAction } from "../../Reducers/product_reducer";
import { CameraOutlined } from "@ant-design/icons";
import { scanBarcode } from "../../Utils/barCodeScanner";
import { barcodeAction } from "../../Reducers/barcode_reducer";

export default function AddBarcode(props: any) {
  const [form] = Form.useForm();
  let [pro, setPro] = useState(null);
  let [sltCode, setSltCOde] = useState([]); // for code load.
  let [, setAddBtnLoading] = useState(true);
  let [state, dispatch] = useStateContext();
  let data = props.data;
  let modalVisiable = props.modalVisiable;
  let setModalVisiable = props.setModalVisiable;
  let setIsBrother = props.setIsBrother;
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (state.product.currentID) {
      // fast render content.
      setAddBtnLoading(true);
      getProInfo(state.product.currentID, (data: any) => {
        setAddBtnLoading(false);
        // getStock(state.product.currentID)
      });
    }
  }, [state.product.currentID]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current!.focus({
          cursor: "start",
        });
      }
    }, 100);
  }, [modalVisiable]);

  function getProInfo(code: string, fn?: (data?: any) => void) {
    //讀取API product信息. fn option callback function

    // fn() is use to call local database stock,location,unit to set unit selection.

    form.setFieldsValue({
      CODE: code,
    });
    axios("https://kfkproapi.kfkhk.com/api/goods/" + code, {
      method: "get",
      params: { abc: "34sfsdf34234sfsds34fsfe34f" },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data[0];
          if (playload) {
            form.setFieldsValue({
              CODE: playload.CODE,
              CBDESC: playload.CBDESC,
              PACKING: playload.price.filter((i: any) => i.STOCKUNIT === 1)[0]
                .PACKING,
            });

            dispatch(
              barcodeAction({
                CODE: playload.CODE,
                CBDESC: playload.CBDESC,
                PRICE: genRetaiPriceList(playload),
                PACKING: playload.price.filter((i: any) => i.STOCKUNIT === 1)[0]
                  .PACKING,
              })
            );

            setPro(playload);
            setIsBrother(false);

            if (fn) {
              fn();
            }

            //setAddBtnLoading(false);
            setModalVisiable(false);
          } else {
            message.warn("無資料!");
          }
        } else {
          message.warn("Server故障!");
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function loadProCode(code: string) {
    if (code.trim() === "") return;
    code = code.toUpperCase();
    axios("https://kfkproapi.kfkhk.com/api/goodsmulti/" + code, {
      method: "get",
      params: { abc: "34sfsdf34234sfsds34fsfe34f" },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data;
          if (playload) {
            setSltCOde(playload);
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function genRetaiPriceList(pro: any) {
    // show 零售價格格式字符

    let str = "";
    pro.price
      .filter((e: any) => {
        return e.RETAILUNIT === 1;
      })
      .forEach((e: any) => {
        str += "$" + e.RETAILUP + "/" + e.UNIT + " , ";
      });
    return str;
  }

  function genStockUnitAndPacking(pro: any) {
    // show 安全倉數格式字符

    let str = "";
    pro.price
      .filter((e: any) => {
        return e.STOCKUNIT === 1;
      })
      .forEach((e: any) => {
        str += " (" + e.PACKING + ") ";
      });
    return str;
  }

  return (
    <>
      <Modal
        visible={modalVisiable}
        onCancel={() => {
          setModalVisiable(false);
          form.resetFields();
          setPro(null);
          setAddBtnLoading(true);
          dispatch(productAction({ currentID: null }));
          setIsBrother(false);
        }}
        footer={false}
        destroyOnClose
        style={{ top: 5 }}
      >
        <div
          style={{
            display: "table",
            width: "100%",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <h1>產品條碼資料</h1>
            </div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}></div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <Form
                size="small"
                name="basic"
                style={{ marginLeft: 20, marginRight: 20 }}
                // initialValues={{  }}
                // onFinish={updateOrAddOrder}
                labelCol={{ span: 3 }}
                form={form}
              >
                <Form.Item name="id" label="id" style={{ display: "none" }}>
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="RATIO_N"
                  label="RATIO_N"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="RATIO_D"
                  label="RATIO_D"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="PACKING"
                  label="PACKING"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  label="產品編號"
                  name="CODE"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  {/* <Input
                    size="large"

                  /> */}
                  <Select
                    ref={inputRef}
                    onSearch={loadProCode}
                    showSearch
                    onChange={(vl: string) => getProInfo(vl)}
                    size="large"
                  >
                    {sltCode.map((item) => (
                      <Select.Option value={item["CODE"]}>
                        {item["CODE"] + " " + item["CBDESC"]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="產品名稱"
                  name="CBDESC"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Input type="text" readOnly size="large"></Input>
                </Form.Item>
              </Form>
              <div
                style={{
                  display: "table",
                  marginLeft: 20,
                  marginBottom: 20,
                  padding: 3,
                }}
              >
                <div style={{ display: "table-row" }}>
                  <div style={{ display: "table-cell", fontSize: "14pt" }}>
                    零售價格:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      fontSize: "14pt",
                      color: "blue",
                    }}
                  >
                    {pro === null ? "" : genRetaiPriceList(pro)}
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    包裝:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    {pro === null ? "" : genStockUnitAndPacking(pro)}
                  </div>
                </div>
              </div>

              {/* <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                loading={addbtnLoading}
                onClick={() => {
                  setModalVisiable(false)
                 setTimeout(() => {
                   window.print()
                 }, 800);
                 
                }}
              >
               列印
              </Button> */}
              <Button
                style={{ marginRight: 16 }}
                size="large"
                onClick={() => {
                  let vl: { CODE: "" } = form.getFieldsValue();
                  if (vl.CODE) {
                    getProInfo(vl.CODE);
                    // getStock(vl.CODE);
                  } else {
                    getProInfo("PD018-6050B");
                    //  getStock("PD018-6050B");
                  }
                }}
              >
                查找
              </Button>
              <Upload
                accept="image/*;capture=camera"
                multiple={false}
                showUploadList={false}
                beforeUpload={(file: any, FileList: any[]) => {
                  // console.log(file);
                  // console.log(FileList);
                  const reader = new FileReader();

                  reader.onload = (e: any) => {
                    scanBarcode(e.target.result, getProInfo);
                  };
                  reader.readAsDataURL(file);

                  // scanBarcode(file)
                  return false;
                }}
              >
                <Button size="large" icon={<CameraOutlined />}>
                  相機
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
