// this file is share by add new order and edit exist order product.
// Refill.tsx AddItem.tsx
// state.refill.currentID null that mean add new refill.
// back to refill list need clear state.refill.currentID and state.refill.currentID

import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Upload,
  message,
} from "antd";

import axios from "axios";
import { useStateContext } from "../../Store/StateProvider";
import { useHistory } from "react-router-dom";
import {
  CameraOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { scanBarcode } from "../../Utils/barCodeScanner";
import { refillAction } from "../../Reducers/refill_reducer";

export default function AddStoreItem(props: any) {
  const [form] = Form.useForm();
  let history = useHistory();
  let [pro, setPro] = useState(null);
  let [stock, setStock] = useState(null);
  let [addbtnLoading, setAddBtnLoading] = useState(true);
  let [state, dispatch] = useStateContext();
  let data = props.data;
  let modalVisiable = props.modalVisiable;
  let setModalVisiable = props.setModalVisiable;
  let [currentRefillQty, setCurrentRefillQty] = useState("");

  let inputRef = React.useRef<any>(null);

  useEffect(() => {
    if (state.refill.currentID) {
      getExistRefillQTY();
    }
  }, [state.refill.currentID]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current!.focus({
          cursor: "start",
        });
      }
    }, 100);
  }, [modalVisiable]);

  // function getProInfo(code: string, fn?: () => void) {
  //   //讀取API product信息. fn option callback function
  //   form.setFieldsValue({
  //     CODE: code,
  //   });
  //   getStock(code);
  //   getExistRefillQTY();
  //   axios("https://kfkproapi.kfkhk.com/api/goods/" + code, {
  //     method: "get",
  //     params: { abc: "34sfsdf34234sfsds34fsfe34f" },
  //   })
  //     .then((v) => {
  //       if (v.status === 200) {
  //         let playload = v.data.data[0];
  //         if (playload) {
  //           form.setFieldsValue({
  //             CODE: playload.CODE,
  //             CBDESC: playload.CBDESC,
  //           });
  //           setPro(playload);

  //           if (fn) {
  //             fn();
  //           }
  //           setAddBtnLoading(false);
  //         } else {
  //           message.warn("無資料!");
  //         }
  //       } else {
  //         message.warn("Server故障!");
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.message);
  //     });
  // }

  function getStock(code: string) {
    //讀取產品庫倉(本地資料庫).

    axios("/api/products", {
      method: "get",
      params: { searchField: "CODE", searchContent: code },
    })
      .then((v) => {
        if (v.status === 200 && v.data.data[0].length > 0) {
          let d = v.data.data[0][0];
          setStock(d);
          form.setFieldsValue({ SHOP_GOODS_LOC: d.SHOP_GOODS_LOC });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function getExistRefillQTY() {
    // 讀取現有今日要補貨而未處理數量
    axios("/api/refill", {
      method: "get",
      params: { searchField: "id", searchContent: state.refill.currentID },
    })
      .then((v) => {
        if (v.status === 200 && v.data.data[0].length > 0) {
          data = v.data.data[0][0];
          setCurrentRefillQty(`${data.QTY}${data.UNIT}`);
          form.setFieldsValue({
            id: state.refill.currentID,
            CODE2: data.CODE,
            QTY: data.QTY,
            CBDESC: data.CBDESC,
            UNIT: data.UNIT,
            RATIO_N: data.RATIO_N,
            RATIO_D: data.RATIO_D,
            RETAILUP: data.RETAILUP,
            TOTAL: data.TOTAL,
          });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function genRetaiPriceList(pro: any) {
    // show 零售價格格式字符

    let str = "";
    pro.price
      .filter((e: any) => {
        return e.RETAILUNIT === 1;
      })
      .forEach((e: any) => {
        str += "$" + e.RETAILUP + "/" + e.UNIT + " , ";
      });
    return str;
  }

  function genStockUnitAndPacking(pro: any) {
    // show 安全倉數格式字符

    let str = "";
    pro.price
      .filter((e: any) => {
        return e.STOCKUNIT === 1;
      })
      .forEach((e: any) => {
        str += e.UNIT + " (" + e.PACKING + ") ";
      });
    return pro.safestock.ROL + str;
  }

  function updateOrAddOrder(v: any) {
    if (state.refill.currentID !== null) {
      // add new order

      setAddBtnLoading(true);
      axios
        .put("/api/confirmRefill", {
          id: v.id,
          CODE: v.CODE,
          CBDESC: v.CBDESC,
          QTY: v.QTY,
          UNIT: v.UNIT,
          RATIO_N: v.RATIO_N,
          RATIO_D: v.RATIO_D,
          RETAILUP: v.RETAILUP,
          TOTAL: v.RETAILUP * v.QTY,
          SHOP_GOODS_LOC: v.SHOP_GOODS_LOC,
        })
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              setModalVisiable(false);
              setCurrentRefillQty("");
              form.resetFields();
              dispatch(
                refillAction({
                  pageNo: 1,
                  reloadFlag: !state.refill.reloadFlag,
                  currentID: null,
                })
              );
             
            } else {
              Modal.error({
                title: "Add record failed.",
                content: JSON.stringify(v.data.msg),
              });
              dispatch(
                refillAction({
                  reloadFlag: !state.refill.reloadFlag,
                })
              );
              setAddBtnLoading(false);
            }
          }
        })
        .catch((e) => {
          Modal.error({ title: "Add user failed." });
          console.log(e);
          setAddBtnLoading(false);
        });
    }else{
      message.warning('ID error.')
    }
  }

  return (
    <>
      <Modal
        visible={modalVisiable}
        onCancel={() => {
          setModalVisiable(false);
          form.resetFields();
          setPro(null);
          setStock(null);
          setCurrentRefillQty("");
          dispatch(refillAction({ currentID: null }));
          setAddBtnLoading(true);
        }}
        footer={false}
        destroyOnClose
        style={{ top: 5 }}
      >
        <div
          style={{
            display: "table",
            width: "100%",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <h1>倉庫補貨數量</h1>
            </div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <Form
                size="small"
                name="basic"
                style={{ marginLeft: 20, marginRight: 20 }}
                // initialValues={{  }}
                onFinish={updateOrAddOrder}
                labelCol={{ span: 3 }}
                form={form}
                initialValues={{ QTY: 1 }}
              >
                <Form.Item name="id" label="id" style={{ display: "none" }}>
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="CODE2"
                  label="CODE2"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="RATIO_N"
                  label="RATIO_N"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="RATIO_D"
                  label="RATIO_D"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="PACKING"
                  label="PACKING"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="RETAILUP"
                  label="RETAILUP"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  name="SHOP_GOODS_LOC"
                  label="SHOP_GOODS_LOC"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>
                <Form.Item
                  label="產品編號"
                  name="CODE"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Input
                    ref={inputRef}
                    readOnly
                    size="large"
                    onClick={(e) => {
                      if (navigator.clipboard) {
                        navigator.clipboard
                          .readText()
                          .then((text) => {
                            if (text) {
                              //   getProInfo(text);
                              form.setFieldsValue({CODE:text})
                              if (form.getFieldValue("CODE") === form.getFieldValue("CODE2")) {                    
                                setAddBtnLoading(false);
                              }
                            }
                          })
                          .catch((e) => console.log(e));
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key !== "Shift" && e.key !== "Enter") {
                        form.setFieldsValue({
                          CODE:
                            (form.getFieldValue("CODE")
                              ? form.getFieldValue("CODE")
                              : "") + e.key,
                        });
                      }
                      if (e.key === "Enter") {
                        let vl: { CODE: "" } = form.getFieldsValue();
                        if (vl.CODE) {
                          //  getProInfo(vl.CODE);
                          if (form.getFieldValue("CODE") === form.getFieldValue("CODE2")) {                    
                            setAddBtnLoading(false);
                          }
                        }
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="CBDESC"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Input size="large" readOnly></Input>
                </Form.Item>
                <Form.Item
                  label="產品單位"
                  name="UNIT"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <Select
                    onChange={(v) =>
                      (pro as any).price
                        .filter((p: any) => p.UNIT === v)
                        .map((m: any) => {
                          form.setFieldsValue({
                            RETAILUP: m.RETAILUP,
                            RATIO_N: m.RATIO_N,
                            RATIO_D: m.RATIO_D,
                          });
                          return (
                            <Select.Option key={m.UNIT} value={m.UNIT}>
                              {m.UNIT}
                            </Select.Option>
                          );
                        })
                    }
                  >
                    {pro === null
                      ? ""
                      : (pro as any).price
                          .filter((p: any) => p.RETAILUNIT === 1)
                          .map((m: any, index: number) => {
                            if (index === 0) {
                              form.setFieldsValue({
                                UNIT: m.UNIT,
                                RETAILUP: m.RETAILUP,
                                RATIO_N: m.RATIO_N,
                                RATIO_D: m.RATIO_D,
                              });
                            }
                            return (
                              <Select.Option key={m.UNIT} value={m.UNIT}>
                                {m.UNIT}
                              </Select.Option>
                            );
                          })}
                  </Select>
                </Form.Item>
                <span style={{ display: "block", color: "red" }}>
                  {currentRefillQty && `此貨品補貨有${currentRefillQty}未處理`}
                </span>
                <br />
                <Form.Item
                  label="訂購數量"
                  name="QTY"
                  rules={[{ required: true }]}
                  hasFeedback
                  noStyle
                >
                  <InputNumber size="large" readOnly />
                </Form.Item>{" "}
                <CaretUpOutlined
                  style={{ fontSize: "22pt", color: "green" }}
                  onClick={() => {
                    form.setFieldsValue({ QTY: form.getFieldValue("QTY") + 1 });
                  }}
                />{" "}
                <CaretDownOutlined
                  style={{ fontSize: "22pt", color: "green" }}
                  onClick={() => {
                    if (form.getFieldValue("QTY") > 1) {
                      form.setFieldsValue({
                        QTY: form.getFieldValue("QTY") - 1,
                      });
                    }
                  }}
                />
              </Form>
              <div
                style={{
                  display: "table",
                  marginLeft: 20,
                  marginBottom: 20,
                  padding: 3,
                }}
              >
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 10,
                      fontSize: "14pt",
                    }}
                  >
                    貨架與倉數:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 10,
                      fontSize: "14pt",
                    }}
                  >
                    {stock === null
                      ? ""
                      : (stock as any).SHOP_GOODS_LOC +
                        ", " +
                        (stock as any).SHOP_STOCK +
                        (stock as any).UNIT}
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div style={{ display: "table-cell", fontSize: "14pt" }}>
                    零售價格:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      fontSize: "14pt",
                      color: "blue",
                    }}
                  >
                    {pro === null ? "" : genRetaiPriceList(pro)}
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    安全倉數:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    {pro === null ? "" : genStockUnitAndPacking(pro)}
                  </div>
                </div>
              </div>

              <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                loading={addbtnLoading}
                onClick={() => form.submit()}
              >
                補貨
              </Button>
              <Button
                style={{ marginRight: 16 }}
                size="large"
                onClick={() => {
                  let vl: { CODE: "" } = form.getFieldsValue();
                  if (vl.CODE) {
                    // getProInfo(vl.CODE);
                    // getStock(vl.CODE);
                    if (vl.CODE === form.getFieldValue("CODE2")) {
                      form.setFieldsValue({ CODE: vl.CODE });
                      setAddBtnLoading(false);
                    }
                  } else {
                    //  getProInfo("PD018-6050B");
                    form.setFieldsValue({ CODE: "PD018-6050B" });
                    if (form.getFieldValue("CODE") === form.getFieldValue("CODE2")) {                    
                      setAddBtnLoading(false);
                    }
                    //  getStock("PD018-6050B");
                  }
                }}
              >
                查找
              </Button>
              <Upload
                accept="image/*;capture=camera"
                multiple={false}
                showUploadList={false}
                beforeUpload={(file: any, FileList: any[]) => {
                  // console.log(file);
                  // console.log(FileList);
                  const reader = new FileReader();

                  reader.onload = (e: any) => {
                    scanBarcode(e.target.result);
                  };
                  reader.readAsDataURL(file);

                  // scanBarcode(file)
                  return false;
                }}
              >
                <Button size="large" icon={<CameraOutlined />}>
                  相機
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
