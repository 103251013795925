// state.order.itemID not null that mean modify order items
// state.order.currentID null that mean add new order.
// back to order list need clear state.order.currentID and state.order.itemID and state.order.order_no

import React, { useEffect, useState } from "react";
import { Button, DatePicker, List } from "antd";
import { useStateContext } from "../../Store/StateProvider";
import { useHistory } from "react-router-dom";
import { getDataForTable } from "../../Utils/getDataForTable";
import { reportAction } from "../../Reducers/report_reducer";
import dayjs from "dayjs";
import axios from "axios";
export default function Report(props: any) {
  let [data, setData] = useState([
    { CODE: "", CBDESC: "", QTY: 0, UNIT: 0, TOTAL: 0 },
  ]);
  let [pageTotal, setPageTotal] = useState();
  let [state, dispatch] = useStateContext();
  let [start, setStart] = useState(dayjs().format("YYYY-MM-DD"));
  let [end, setEnd] = useState(dayjs().format("YYYY-MM-DD"));
  let [total, setTotal] = useState();
  let history = useHistory();

  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
  ] = Object.values(state.report);
  between = [
    "updated_date",
    start,
    dayjs(end).add(1, "day").format("YYYY-MM-DD"),
  ];

  useEffect(() => {
    getDataForTable(
      {
        pageSize,
        pageNo,
        searchField,
        searchContent,
        between,
        conditionType,
        queryType,
        orderBy,
        sortBy,
      },
      setData,
      setPageTotal,
      "/api/orderitems",
      "GET"
    );
   getTotal()
  }, [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    state.report.reloadFlag,
  ]);

  function getTotal() {
    axios("/api/orderitemstotal", {
      method: "get",
      params: { between: ["updated_date", start, dayjs(end).add(1, "day").format("YYYY-MM-DD")] },
    }).then((v) => {
      if (v.status === 200) {
        if (v.data.success) {
          setTotal(v.data.data?.total);
        }
      }
    });
  }

  return (
    <>
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: 800,
        }}
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <h1>銷售報告</h1>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <div style={{ display: "table-row" }}>
          <div
            style={{ display: "table-cell", textAlign: "center", padding: 5 }}
          >
            <DatePicker onChange={(v, datastr) => setStart(datastr)} />
            {"->"}
            <DatePicker onChange={(v, datastr) => setEnd(datastr)} />
          </div>
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{ display: "table-cell", textAlign: "center", padding: 5 }}
          >
            <Button
              onClick={() =>
                dispatch(reportAction({ reloadFlag: !state.report.reloadFlag,pageNo:1 }))
              }
            >
              查找
            </Button>
          </div>
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{ display: "table-cell", textAlign: "center", padding: 5 }}
          >
            <List
              style={{ maxHeight: 390, overflow: "scroll" }}
              size="large"
              bordered
              pagination={{
                onChange: (page) => {
                  dispatch(
                    reportAction({
                      pageNo: page,
                      reloadFlg: !state.report?.reloadFlag,
                    })
                  );
                },
                current: pageNo,
                total: pageTotal,
                pageSize: pageSize,
                defaultPageSize: 5,
              }}
              dataSource={data}
              renderItem={(item: any) => (
                <List.Item>
                  <div
                    style={{
                      display: "table",
                      width: "100%",
                      padding: 0,
                      fontSize: "13pt",
                    }}
                  >
                    <div style={{ display: "table-cell", width: "50%" }}>
                      {item.CBDESC}
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        width: "25%",
                        textAlign: "right",
                      }}
                    >
                      {item.QTY} {item.UNIT}
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        width: "25%",
                        textAlign: "right",
                      }}
                    >
                      ${item.TOTAL}
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{ display: "table-cell", textAlign: "center", padding: 5 }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: "table",
          width: "100%",
          position: "fixed",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          >
            <div>{start + "->" + end}</div>
            <span style={{ fontWeight: "bold", color: "blue" }}>銷售總額:${total}</span>
          </div>
        </div>
      </div>
    </>
  );
}
