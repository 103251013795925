// state.order.itemID not null that mean modify order items
// state.order.currentID null that mean add new order.
// back to order list need clear state.order.currentID and state.order.itemID and state.order.order_no

import React, { useEffect, useState } from "react";
import { Button, List } from "antd";
import { getDataForTable } from "../../Utils/getDataForTable";
import { CheckOutlined } from "@ant-design/icons";
import { useStateContext } from "../../Store/StateProvider";
import { defaultStateType } from "../../Store/defaultStatus";
import {useHistory} from 'react-router-dom'

import {
  orderAction,
} from "../../Reducers/order_reducer";

export default function ListOrder(props: any) {
  let [data, setData] = useState([]);
  let [pageTotal, setPageTotal] = useState();
  let [state, dispatch] = useStateContext();
  let history=useHistory()

  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
  ] = Object.values(state.order);

  useEffect(() => {
    getDataForTable(
      {
        pageSize,
        pageNo,
        searchField,
        searchContent,
        between,
        conditionType,
        queryType,
        orderBy,
        sortBy,
      },
      setData,
      setPageTotal,
      "/api/orders",
      "GET"
    );
    // fetchTableData();
  }, [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    state.order.reloadFlag,
  ]);

  return (
    <div
      style={{
        display: "table",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <div style={{ display: "table-row" }}>
        <div style={{ display: "table-cell", textAlign: "center" }}>
          <List
           style={{maxHeight:480,height:475,overflow: 'hidden'}}
            pagination={{
              onChange: (page) => {
                dispatch(
                  orderAction({
                    pageNo: page,
                    reloadFlg: !(data as defaultStateType).order?.reloadFlag,
                  })
                );
              },
              current: pageNo,
              total: pageTotal,
              pageSize: pageSize,
              defaultPageSize: 6,
            }}
            size="large"
            bordered
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item>
                <div style={{ display: "table", width: "100%" }}>
                  <div style={{ display: "table-cell", width: "50%" }}>
                    {item.order_no}
                  </div>{" "}
                  <div style={{ display: "table-cell" }}>
                    {item.finished ? (
                     
                      <Button
                        type="text"
                        onClick={() => {
                          dispatch(orderAction({currentID:item.id,order_no:item.order_no}));
                          history.push('/main/orderdetail')
                        }}
                        icon={ <CheckOutlined style={{ color: "green" }} />}
                      >
                        查看
                      </Button>
                    ) : (
                      <Button
                        type="dashed"
                        onClick={() => {
                          dispatch(orderAction({currentID:item.id,order_no:item.order_no}));
                          history.push('/main/orderdetail')
                        }}
                      >
                        修改
                      </Button>
                    )}
                  </div>{" "}
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
}
