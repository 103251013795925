import React, { createContext, useContext, Context, useReducer } from "react";
export const StateContext: Context<any> = createContext("");

export const StateProvider: React.FC<any> = ({
  reducer,
  initialState,
  children,
}: {
  reducer: any;
  initialState: any;
  children: any;
}) => {
  
  let stateValue = (() => {
    return localStorage.getItem("state")
      ? JSON.parse(localStorage.getItem("state") || "")
      : initialState;
  })();

  return (
    <StateContext.Provider value={useReducer(reducer, stateValue)}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
