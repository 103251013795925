import React, { useState, useEffect, useMemo } from "react";
import { Button, Form, Input, Select, Switch, Modal } from "antd";
import axios from "axios";
import {
  getDataForTable,
  SearchParamsInterface,
} from "../../Utils/getDataForTable";
import { useStateContext } from "../../Store/StateProvider";
import UserList from "./UserList";

export default function User(props: any) {
  let [state, dispatch] = useStateContext();
  let [userModalVisiable, setUserModalVisiable] = useState(false);
  let [addBtnLoading, setAddBtnLoading] = useState(false);
  let [editBtnLoading, setEditBtnLoading] = useState(false);
  let [delBtnLoading, setDelBtnLoading] = useState(false);

  let [data, setData] = useState([]);
  let [pageTotal, setPageTotal] = useState();
  let [pageNo, setPageNo] = useState(1);
  let [pageSize] = useState(100);
  let [searchField, setSearchField] = useState([""]);
  let [searchContent, setSearchContent] = useState([""]);
  let [between] = useState("");
  let [conditionType, setConditionType] = useState("and");
  let [queryType, setQueryType] = useState("like");
  let [orderBy, setOrderBy] = useState("id");
  let [sortBy, setSortBy] = useState("DESC");

  let [user, setUser] = useState({
    id: 0,
    user_name: "",
    login_name: "",
    password: "",
    group: "user",
    enabled: true,
    type: "add",
  });

  // Memo fetch table data.
  const fetchTableData = useMemo(
    () => () =>
      getDataForTable(
        {
          pageSize,
          pageNo,
          searchField,
          searchContent,
          between,
          conditionType,
          queryType,
          orderBy,
          sortBy,
        } as SearchParamsInterface,
        setData,
        setPageTotal,
        "/api/users",
        "GET"
      ),
    [
      pageSize,
      pageNo,
      searchField,
      searchContent,
      between,
      conditionType,
      queryType,
      orderBy,
      sortBy,
    ]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  function updateOrAddUser(v: any) {
    if (user.type === "add") {
      // add user
      setAddBtnLoading(true);
      axios
        .post("/api/user", v)
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              //Modal.info({ title: "Add user success" });
              setUserModalVisiable(false);
              setAddBtnLoading(false);
              fetchTableData();
            } else {
              Modal.error({
                title: "Add user failed.",
                content: JSON.stringify(v.data.msg),
              });
              //setUserModalVisiable(false);
              setAddBtnLoading(false);
            }
          }
        })
        .catch((e) => {
          Modal.error({ title: "Add user failed." });
        });
    } else {
      // edituser
      setEditBtnLoading(true);
      axios
        .put("/api/user", v)
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              //Modal.info({ title: "Add user success" });
              setUserModalVisiable(false);
              fetchTableData();
            } else {
              Modal.error({
                title: "Edit user failed.",
                content: JSON.stringify(v.data.msg),
              });
            }
            setEditBtnLoading(false);
          }
        })
        .catch((e) => {
          Modal.error({ title: "Edit user failed." });
          setEditBtnLoading(false);
        });
    }
  }

  // Delete user
  function delUser(userid: number) {
    axios
      .delete("/api/user", { data: { id: userid } })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            Modal.info({ title: "User deleted." });
            fetchTableData();
            setUserModalVisiable(false);
            setDelBtnLoading(false);
          } else {
            Modal.error({ title: "Error", content: v.data.msg });
          }
        } else {
          Modal.error({ title: "Server error" });
        }
      })
      .catch((e) => {
        Modal.error({ title: "network error" });
      });
  }

  return (
    <>
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: 800
        }}
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell",textAlign:'center' }}><h1>用戶列表</h1></div>        
        </div>
      </div>
      <Modal
        visible={userModalVisiable}
        onCancel={() => {
          setUserModalVisiable(false);
        }}
        footer={false}
        destroyOnClose
        style={{ top: 10 }}
      >
        <div
          style={{
            display: "table",
            width: "100%",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              {user.type === "add" ? <h1>加入用戶</h1> : <h1>修改用戶</h1>}
            </div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <Form
                size="small"
                name="basic"
                style={{ marginLeft: 20, marginRight: 20 }}
                initialValues={user}
                onFinish={updateOrAddUser}
                labelCol={{ span: 5 }}
              >
                <Form.Item name="id" label="id" style={{ display: "none" }}>
                  <Input value={user.id}></Input>
                </Form.Item>

                <Form.Item
                  label="用戶名"
                  name="user_name"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="登入賬號"
                  name="login_name"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="密碼"
                  name="password"
                  rules={[{ required: true, min: 5 }]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Group"
                  name="group"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <Select>
                    <Select.Option key="1" value="user">
                      普通用戶(user)
                    </Select.Option>
                    <Select.Option key="2" value="admin">
                      管理員(admin)
                    </Select.Option>
                    <Select.Option key="2" value="user_no_price">
                      普通用戶無價錢
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Enable"
                  name="enabled"
                  valuePropName="checked"
                >
                  <Switch defaultChecked={true} />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }}>
                  {user.type === "add" ? (
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      style={{ marginRight: 16 }}
                      loading={addBtnLoading}
                    >
                      加入
                    </Button>
                  ) : (
                    <>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        style={{ marginRight: 16 }}
                        loading={editBtnLoading}
                      >
                        保存
                      </Button>
                      <Button
                        type="primary"
                        danger
                        size="large"
                        onClick={(e) => {
                          setDelBtnLoading(true);
                          delUser(user.id);
                        }}
                        loading={delBtnLoading}
                      >
                        刪除
                      </Button>
                    </>
                  )}
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
      <UserList
        data={data}
        setUser={setUser}
        setUserModalVisiable={setUserModalVisiable}
      />
      <div
        style={{
          display: "table",
          width: "100%",
          position: "fixed",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        
        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 70,
            }}
          >
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setUser({
                  id: 0,
                  user_name: "",
                  login_name: "",
                  password: "",
                  group: "user",
                  enabled: true,
                  type: "add",
                });
                setUserModalVisiable(true);
              }}
            >
              加入新用戶
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
