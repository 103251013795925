/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Button, Modal, Tooltip } from "antd";
import axios from 'axios';
import { useStateContext } from '../Store/StateProvider';
import { setLoginStateAction } from '../Reducers/login_reducer';
import {LogoutOutlined} from '@ant-design/icons'

export default (props: any) => {
    
    let [,dispatch] = useStateContext()
    const doLogout=()=>{
        axios.get('/api/logout').then(
            v=>{
                if(v.status===200){
                    if(v.data.success){
                        localStorage.removeItem('state')
                        localStorage.clear()
                        dispatch(setLoginStateAction({state:'false'}))
                        props.history.push("/login");
                    }else{
                        Modal.error({
                            title:'Error',
                            content:'Logout error..'
                        })
                    }
                }else{
                    Modal.error({
                        title:'Error',
                        content:'Network error..'
                    }) 
                }
            }
        )
    }
    const confirmLogut=()=>{
        Modal.confirm({ 
            title:'你確定要登出系統嗎?',
            onOk:doLogout

        })
    }
    return<>
    <Tooltip placement="bottom" title='Logout'>
    <Button onClick={confirmLogut} type='primary' danger　icon={<LogoutOutlined />}>登出</Button>
    </Tooltip>
    </>
};
