import React from "react";
import { Form, Input, Button, Modal } from "antd";
import { useStateContext } from "../Store/StateProvider";
import axios from "axios";
import { setLoginStateAction } from "../Reducers/login_reducer";

const Login = (props: any) => {
  const [, dispatch] = useStateContext();


  const onFinish = (values:any) => {
    console.log("Success:", values);
    axios.post("/api/login", values).then(v => {
      if (v.status === 200) {
        if (v.data.success) {
          dispatch(setLoginStateAction({state:'true',user:v.data.data.user_name,group:v.data.data.group}));
         props.history.push('/main')
          } else {
          Modal.error({
            title: "Error",
            content: v.data.msg
          });
        }
      }
      console.log(v.data);
    });
  };

  // const onFinishFailed = (errorInfo:any) => {
  //   console.log("Failed:", errorInfo);
  // };

  // const layout = {
  //   labelCol: { span: 8 },
  //   wrapperCol: { span: 16 }
  // };
  const tailLayout = {
    wrapperCol: { offset: 10, }
  };

  return (
    <div >
      <div style={{margin:'auto',marginTop:20,marginBottom:10,fontWeight:24,width:'100%',textAlign:'center',height:40}}><h1>系統登入</h1></div>
      <Form
       // {...layout}
       size='large'
        name="basic"
       style={{marginLeft:20,marginRight:20}}
        onFinish={onFinish}
       // onFinishFailed={onFinishFailed}
      >
  
        <Form.Item
          label="用戶"
          name="username"
          rules={[{ required: true,min:3}]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="密碼"
          name="password"
          rules={[{ required: true,min:3,}]}
        >
          <Input.Password />
        </Form.Item>


        <Form.Item wrapperCol={{span:24}} style={{textAlign:'center'}}>
          <Button type="primary" htmlType="submit">
            登入
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Login;
