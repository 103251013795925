// state.order.itemID not null that mean modify order items
// state.order.currentID null that mean add new order.
// back to order list need clear state.order.currentID and state.order.itemID and state.order.order_no

import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Upload,
  message,
  InputNumber,
} from "antd";

import axios from "axios";
import { useStateContext } from "../../Store/StateProvider";
import { useHistory } from "react-router-dom";
import { productAction } from "../../Reducers/product_reducer";
import { CameraOutlined } from "@ant-design/icons";
import { scanBarcode } from "../../Utils/barCodeScanner";

export default function AddProduct(props: any) {
  const [form] = Form.useForm();
  let history = useHistory();
  let [pro, setPro] = useState(null);
  let [addbtnLoading, setAddBtnLoading] = useState(true);
  let [state, dispatch] = useStateContext();
  let data = props.data;
  let modalVisiable = props.modalVisiable;
  let setModalVisiable = props.setModalVisiable;
  let inputRef = React.useRef<any>(null);
  useEffect(() => {
    if (state.product.currentID) {
      // fast render content.
      setAddBtnLoading(true);
      getProInfo(state.product.currentID, (data:any) => {setAddBtnLoading(false);getStock(state.product.currentID)});
  
    }
  }, [state.product.currentID]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current!.focus({
          cursor: "start",
        });
      }
    }, 100);
  }, [modalVisiable]);


  function getProInfo(code: string, fn?: (data?:any) => void) {
    //讀取API product信息. fn option callback function

    // fn() is use to call local database stock,location,unit to set unit selection.

    form.setFieldsValue({
      CODE: code,
    });
    axios("https://kfkproapi.kfkhk.com/api/goods/" + code, {
      method: "get",
      params: { abc: "34sfsdf34234sfsds34fsfe34f" },
    })
      .then((v) => {
        if (v.status === 200) {
          let playload = v.data.data[0];
          if (playload) {
            form.setFieldsValue({
              CODE: playload.CODE,
              CBDESC: playload.CBDESC,
              PACKING: playload.price.filter((i: any) => i.STOCKUNIT === 1)[0]
                .PACKING,
            });
            setPro(playload);

            if (fn) {
              fn();
            }
            setAddBtnLoading(false);
          } else {
            message.warn("無資料!");
          }
        } else {
          message.warn("Server故障!");
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function getStock(code: string) {
    //讀取產品庫倉(本地資料庫).

    axios("/api/products", {
      method: "get",
      params: { searchField: "CODE", searchContent: code },
    })
      .then((v) => {
        if (v.status === 200 && v.data.data[0].length > 0) {
          let d = v.data.data[0][0];
          form.setFieldsValue({
            SHOP_GOODS_LOC:d.SHOP_GOODS_LOC,
            SHOP_STOCK:d.SHOP_STOCK,
            SAVE_STOCK:d.SAVE_STOCK
          })

          let unit=form.getFieldsValue().UNIT

          if(d.UNIT!==unit){
            form.setFieldsValue({
              UNIT:d.UNIT,
              RATIO_N:d.RATIO_N,
              RATIO_D:d.RATIO_D
            })
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function genRetaiPriceList(pro: any) {
    // show 零售價格格式字符

    let str = "";
    pro.price
      .filter((e: any) => {
        return e.RETAILUNIT === 1;
      })
      .forEach((e: any) => {
        str += "$" + e.RETAILUP + "/" + e.UNIT + " , ";
      });
    return str;
  }

  function genStockUnitAndPacking(pro: any) {
    // show 安全倉數格式字符

    let str = "";
    pro.price
      .filter((e: any) => {
        return e.STOCKUNIT === 1;
      })
      .forEach((e: any) => {
        str += " (" + e.PACKING + ") ";
      });
    return  str;
  }

  function updateOrAddOrder(v: any) {
    // add new or update

    setAddBtnLoading(true);
    axios
      .post("/api/product", {
        CODE: v.CODE,
        CBDESC: v.CBDESC,
        QTY: v.QTY,
        UNIT: v.UNIT,
        RATIO_N: v.RATIO_N,
        RATIO_D: v.RATIO_D,
        RETAILUP: v.RETAILUP,
        PACKING: v.PACKING,
        SHOP_GOODS_LOC: v.SHOP_GOODS_LOC,
        SHOP_STOCK:v.SHOP_STOCK,
        SAVE_STOCK:v.SAVE_STOCK
      })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            setModalVisiable(false);
            form.resetFields();
            dispatch(
              productAction({
                pageNo: 1,
                reloadFlag: !state.product.reloadFlag,
                currentID: null,
              })
            );
            history.push("/main/product");
          } else {
            Modal.error({
              title: "Add record failed.",
              content: JSON.stringify(v.data.msg),
            });

            setAddBtnLoading(false);
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Add product failed." });
        console.log(e);
        setAddBtnLoading(false);
      });
  }

  return (
    <>
      <Modal
        visible={modalVisiable}
        onCancel={() => {
          setModalVisiable(false);
          form.resetFields();
          setPro(null);
          setAddBtnLoading(true);
          dispatch(productAction({ currentID: null }));
        }}
        footer={false}
        destroyOnClose
        style={{ top: 5 }}
      >
        <div
          style={{
            display: "table",
            width: "100%",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <h1>產品資料</h1>
            </div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}></div>
          </div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "center" }}>
              <Form
                size="small"
                name="basic"
                style={{ marginLeft: 20, marginRight: 20 }}
                // initialValues={{  }}
                onFinish={updateOrAddOrder}
                labelCol={{ span: 3 }}
                form={form}
              >
                <Form.Item name="id" label="id" style={{ display: "none" }}>
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="RATIO_N"
                  label="RATIO_N"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="RATIO_D"
                  label="RATIO_D"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  name="PACKING"
                  label="PACKING"
                  style={{ display: "none" }}
                >
                  <Input value={""}></Input>
                </Form.Item>

                <Form.Item
                  label="產品編號"
                  name="CODE"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Input
                  ref={inputRef}
                    size="large"
                    onClick={() => {
                      if (navigator.clipboard) {
                        navigator.clipboard
                          .readText()
                          .then((text) => {
                            if (text) {
                              getProInfo(text);
                            }
                          })
                          .catch((e) => console.log(e));
                      }
                    }}

                    onKeyDown={(e)=>{
                      // if(e.key!=='Shift'&&e.key!=='Enter'){
                      //   form.setFieldsValue({CODE:(form.getFieldValue('CODE')?form.getFieldValue('CODE'):'')+e.key})
                      // }
                      if(e.key==='Enter'){
                        let vl: { CODE: "" } = form.getFieldsValue();
                        if (vl.CODE) {
                          getProInfo(vl.CODE,()=>getStock(vl.CODE));                     
                        }
                      }

                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="產品名稱"
                  name="CBDESC"
                  rules={[{ required: true, min: 4 }]}
                  hasFeedback
                >
                  <Input type="text" readOnly size="large"></Input>
                </Form.Item>

                <Form.Item
                  label="產品單位"
                  name="UNIT"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <Select
                    onChange={(v) =>
                      (pro as any).price
                        .filter((p: any) => p.UNIT === v)
                        .map((m: any) => {
                          form.setFieldsValue({
                            RETAILUP: m.RETAILUP,
                            RATIO_N: m.RATIO_N,
                            RATIO_D: m.RATIO_D,
                          });
                          return (
                            <Select.Option key={m.UNIT} value={m.UNIT}>
                              {m.UNIT}
                            </Select.Option>
                          );
                        })
                    }
                  >
                    {pro === null
                      ? ""
                      : (pro as any).price
                          .filter((p: any) => p.RETAILUNIT === 1)
                          .map((m: any, index: number) => {
                            if (index === 0) {
                              form.setFieldsValue({
                                UNIT: m.UNIT,
                                RETAILUP: m.RETAILUP,
                                RATIO_N: m.RATIO_N,
                                RATIO_D: m.RATIO_D,
                              });
                            }
                            return (
                              <Select.Option key={m.UNIT} value={m.UNIT}>
                                {m.UNIT}
                              </Select.Option>
                            );
                          })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="修改倉數"
                  name="SHOP_STOCK"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <InputNumber  />
                </Form.Item>
                <Form.Item
                  label="修改安全倉數"
                  name="SAVE_STOCK"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <InputNumber />
                </Form.Item>               
                <Form.Item
                  label="修改貨架位置"
                  name="SHOP_GOODS_LOC"
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Form>
              <div
                style={{
                  display: "table",
                  marginLeft: 20,
                  marginBottom: 20,
                  padding: 3,
                }}
              >
                <div style={{ display: "table-row" }}>
                  <div style={{ display: "table-cell", fontSize: "14pt" }}>
                    零售價格:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      fontSize: "14pt",
                      color: "blue",
                    }}
                  >
                    {pro === null ? "" : genRetaiPriceList(pro)}
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    包裝:{" "}
                  </div>
                  <div
                    style={{
                      display: "table-cell",
                      padding: 5,
                      fontSize: "14pt",
                    }}
                  >
                    {pro === null ? "" : genStockUnitAndPacking(pro)}
                  </div>
                </div>
              </div>

              <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                loading={addbtnLoading}
                onClick={() => form.submit()}
              >
                {state.product.currentID !== null ? "修改" : "加入"}
              </Button>
              <Button
                style={{ marginRight: 16 }}
                size="large"
                onClick={() => {
                  let vl: { CODE: "" } = form.getFieldsValue();
                  if (vl.CODE) {
                    getProInfo(vl.CODE,()=>getStock(vl.CODE));
                    // getStock(vl.CODE);
                  } else {
                    getProInfo("PD018-6050B",()=>getStock("PD018-6050B"));
                    //  getStock("PD018-6050B");
                  }
                }}
              >
                查找
              </Button>
              <Upload
                accept="image/*;capture=camera"
                multiple={false}
                showUploadList={false}
                beforeUpload={(file: any, FileList: any[]) => {
                  // console.log(file);
                  // console.log(FileList);
                  const reader = new FileReader();

                  reader.onload = (e: any) => {
                    scanBarcode(e.target.result, getProInfo);
                  };
                  reader.readAsDataURL(file);

                  // scanBarcode(file)
                  return false;
                }}
              >
                <Button size="large" icon={<CameraOutlined />}>
                  相機
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
