import React, { useState } from "react";
import { Button, Drawer, Menu } from "antd";

import Logout from "./Logout";

import { useStateContext } from "../Store/StateProvider";
import Order from "./Order/Order";
import { Route } from "react-router-dom";
import User from "./User/User";
import OrderDetail from "./Order/OrderDetail";
import Product from "./Product/Product";
import Report from "./Report/Report";
import Po from "./Po/Po";
import PoDetail from "./Po/PoDetail";
import Barcode from "./Barcode/Barcode";
import Refill from "./Refill/Refill";
import RefillStore from "./Refill/RefillStore";
import LessStock from "./Refill/LessStock";
import PoDetailPrint from "./Po/PoDetailPrint";


export default function Main(props: any) {
  let [state] = useStateContext();
  let [drawer_visiable, setDrawerVisable] = useState(false);

  return (
    <>
      <Drawer
        title="功能列表"
        placement="left"
        closable={false}
        onClose={() => setDrawerVisable(false)}
        visible={drawer_visiable}
        key="basedrawer"
      >
        <Menu
          defaultSelectedKeys={["1"]}
          mode="inline"
          style={{ fontSize: "12pt", fontWeight: "bold" }}
        >
          {/* <Menu.Item
            key="1"
            onClick={() => {
              props.history.push("/main");
              setDrawerVisable(false);
            }}
          >
            加入補貨
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              props.history.push("/main/store");
              setDrawerVisable(false);
            }}
          >
            倉庫執貨
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => {
              props.history.push("/main/lessstock");
              setDrawerVisable(false);
            }}
          >
            欠貨列表
          </Menu.Item> */}
          <Menu.Item
            key="6"
            onClick={() => {
              props.history.push("/main/po");
              setDrawerVisable(false);
            }}
          >
            採購列表
          </Menu.Item>

          {state.login_state.group != "user_no_price" ? (
            <Menu.Item
              key="5"
              onClick={() => {
                props.history.push("/main/barcode");
                setDrawerVisable(false);
              }}
            >
              列印條碼
            </Menu.Item>
          ) : ("")
          }
          {state.login_state.group != "user_no_price" ? (
            <Menu.Item
              key="4"
              onClick={() => {
                props.history.push("/main/product");
                setDrawerVisable(false);
              }}
            >
              貨品貨架
            </Menu.Item>
          ) : ("")

          }
          {/*  <Menu.Item
            key="7"
            onClick={() => {
              props.history.push("/main/order");
              setDrawerVisable(false);
            }}
          >
            訂單列表
          </Menu.Item> */}

          {state.login_state.group === "admin" ? (
            <Menu.Item
              key="8"
              onClick={() => {
                props.history.push("/main/user");
                setDrawerVisable(false);
              }}
            >
              用戶管理
            </Menu.Item>
          ) : (
            ""
          )}
          {state.login_state.group === "admin" ? (
            <Menu.Item
              key="9"
              onClick={() => {
                props.history.push("/main/report");
                setDrawerVisable(false);
              }}
            >
              查看報表
            </Menu.Item>
          ) : (
            ""
          )}
        </Menu>
        <div style={{ display: "block", marginTop: 25, paddingLeft: 18 }}>
          <Logout {...props} />
        </div>
      </Drawer>
      <div style={{ display: "table", width: "100%" }} >
        <div style={{ display: "table-row" }} className="no_print">
          <div style={{ display: "table-cell", padding: 10 }}>
            <Button
              type="primary"
              onClick={() => setDrawerVisable(true)}
              className="no_print"
            >
              功能
            </Button>
          </div>
          <div
            style={{ display: "table-cell", textAlign: "right", padding: 10 }}
            className="no_print"
          >
            <span className="no_print">用戶: {state.login_state.user}</span>
          </div>
        </div>
      </div>

      {/* <Route path={"/main"} exact component={Refill} /> */}
      <Route path={"/main"} exact component={Po} />
      <Route path={"/main/store"} exact component={RefillStore} />
      <Route path={"/main/lessstock"} exact component={LessStock} />
      <Route path={"/main/order"} exact component={Order} />
      <Route path={"/main/orderdetail"} exact component={OrderDetail} />
      <Route path={props.match.url + "/user"} component={User} />
      <Route path={"/main/product"} exact component={Product} />
      <Route path={"/main/report"} exact component={Report} />
      <Route path={"/main/po"} exact component={Po} />
      <Route path={"/main/podetail"} exact component={PoDetail} />
      <Route path={"/main/podetailprint"} exact component={PoDetailPrint} />
      <Route path={"/main/barcode"} exact component={Barcode} />
    </>
  );
}
