import { defaultStateType } from "../Store/defaultStatus";

export function reducer(state: any, action: any) {
  switch (action.type) {
    case "BARCODE":
      return { ...state, ...action.playload };
    default:
      return state;
  }
}

export function barcodeAction(object: defaultStateType) {
  return { type: "BARCODE", playload: object };
}
