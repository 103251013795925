import React, { useEffect, useState } from "react";
import { Button, List, Modal } from "antd";
import AddOrderItem from "./AddStoreItem";
import { useStateContext } from "../../Store/StateProvider";
import { useHistory } from "react-router-dom";
import { getDataForTable } from "../../Utils/getDataForTable";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { refillAction } from "../../Reducers/refill_reducer";
import { genDatetimeFormat } from "../../Utils/genDateTimeFormat";

export default function RefillStore(props: any) {
  let [modalVisiable, setModalVisiable] = useState(false);
  let [state, dispatch] = useStateContext();
  let history = useHistory();
  let [data, setData] = useState([{}]);
  let [, setPageTotal] = useState();
  let [finishBtnLoading, setFinishBtnLoading] = useState(false);
  let [delConfirmVisiable, setDelConfirmVisiable] = useState(false);
  let [delItemConfirmVisiable, setItemDelConfirmVisiable] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
  ] = Object.values(state.refill);
  useEffect(() => {
    getDataForTable(
      {
        pageSize,
        pageNo,
        searchField,
        searchContent,
        between,
        conditionType,
        queryType,
        orderBy,
        sortBy,
      },
      setData,
      setPageTotal,
      "/api/refill",
      "GET"
    );
    // return () => dispatch(orderAction({ currentID: null }));
  }, [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    state.refill.reloadFlag,
  ]);

  useEffect(() => {
    const interval = setTimeout(() => {
      dispatch(refillAction({reloadFlag: !state.refill.reloadFlag}))
    }, 5000);
    return () => clearInterval(interval);
  },[state.refill.reloadFlag]);


  function finishOrder() {
    axios
      .put("/api/finishrefill", { id: state.refill.currentID, state: 0 })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            //Modal.info({ title: "Add user success" });
            setModalVisiable(false);

            dispatch(
              refillAction({
                pageNo: 1,
                reloadFlag: !state.refill.reloadFlag,
                itemID: null,
              })
            );
          } else {
            Modal.error({
              title: "Edit record failed.",
              content: JSON.stringify(v.data.msg),
            });
          }
          setFinishBtnLoading(false);
        }
      })
      .catch((e) => {
        Modal.error({ title: "Edit record failed." });
        setFinishBtnLoading(false);
      });
  }

  function delteItem(id: number, name: string) {
    Modal.confirm({
      title: "你確定要刪除以下項目嗎?",
      icon: <ExclamationCircleOutlined />,
      content: name,
      onOk() {
        // let index = data.items.findIndex((i: any) => i.id === id);
        // data.items.splice(index, 1);
        axios
          .delete("/api/refill", {
            data: { id: id},
          })
          .then((v) => {
            if (v.status === 200) {
              if (v.data.success) {
                //Modal.info({ title: "Add user success" });
                setModalVisiable(false);

                dispatch(
                  refillAction({
                    pageNo: 1,
                    reloadFlag: !state.refill.reloadFlag,
                    itemID: null,
                  })
                );
              } else {
                Modal.error({
                  title: "Edit record failed.",
                  content: JSON.stringify(v.data.msg),
                });
              }
            }
          })
          .catch((e) => {
            Modal.error({ title: "Edit record failed." });
            dispatch(
              refillAction({
                pageNo: 1,
                reloadFlag: !state.refill.reloadFlag,
                itemID: null,
              })
            );
          });
      },
      onCancel() {},
    });
  }

  return (
    <>
      <AddOrderItem
        modalVisiable={modalVisiable}
        setModalVisiable={setModalVisiable}
        data={data}
      />
      <div
        style={{
          display: "table",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          maxWidth: 800,
          marginBottom: 10,
        }}
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell", textAlign: "center" }}>
            <h1>倉庫補貨</h1>
            {genDatetimeFormat('-d')}
          </div>
        </div>
      </div>

      <List
        style={{ maxHeight: 390, overflow: "scroll" }}
        size="large"
        bordered
        dataSource={data}
        renderItem={(item: any) => (
          <List.Item>
            <div
              style={{
                display: "table",
                width: "100%",
                padding: 0,
                fontSize: "13pt",              
              }}
              onClick={(e)=>{setModalVisiable(true);dispatch(refillAction({currentID:item.id}))}}
            >
              <div style={{ display: "table-cell", width: "50%" }}>
                {item.CODE}
                <br />
                {item.CBDESC}
                <br />
                {item.SHOP_GOODS_LOC ? (
                  <span style={{ color: "brown" }}>
                    {"貨架:" + item.SHOP_GOODS_LOC}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "table-cell",
                  width: "25%",
                  textAlign: "right",
                }}
              >
                {item.QTY} {item.UNIT}
              </div>
              <div
                style={{
                  display: "table-cell",
                  width: "25%",
                  textAlign: "right",
                }}
              >
                ${item.TOTAL}
              </div>
              <div style={{ display: "table-cell" }}>
                {/* <Button
                  type="link"
                  onClick={() => {
                    dispatch(refillAction({ itemID: item.id }));
                    setModalVisiable(true);
                  }}
                >
                  修改
                </Button>
                <br />{" "} */}
                <Button
                  style={{ paddingLeft: 15 }}
                  type="link"
                  danger
                  size="small"
                  onClick={() => delteItem(item.id, item.CBDESC)}
                >
                  刪除
                </Button>
              </div>{" "}
            </div>
          </List.Item>
        )}
      />
      <div style={{ display: "table", width: "100%", marginTop: 10 }}>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "left",
              width: "50%",
              paddingLeft: 25,
              fontWeight: "bold",
            }}
          >
            產品項目: {data.length} 款
          </div>

          <div
            style={{
              display: "table-cell",
              textAlign: "right",
              paddingRight: 25,
              fontWeight: "bold",
            }}
          ></div>
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "left",
              paddingLeft: 25,
            }}
          >
            員工: {state.login_state.user}
          </div>
        </div>
      </div>
      {/* <div
        style={{
          display: "table",
          width: "100%",
          position: "fixed",
          bottom: 0,
          maxWidth: 800,
        }}
      >
        <div style={{ display: "table-row", padding: 10 }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          ></div>

          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          >
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setModalVisiable(true);
              }}
            >
              加入產品
            </Button>
          </div>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              padding: 10,
              height: 80,
            }}
          ></div>
        </div>
      </div> */}
    </>
  );
}
