export const checkLoginReducer = (state: any, action: any) => {
  console.log("this is ceckLoginReducer!");
  switch (action.type) {
    case "CHECK_LOGIN":
      return {...action.playload};
    default:
      return state;
  }
};

export const setLoginStateAction = ({
  state,
  user,
  group,
}: {
  state: "true" | "false" | "error" | "loading";
  user?: string;
  group?: string;
}) => {
  if (user === undefined) {
    user = "";
  }
  return {
    type: "CHECK_LOGIN",
    playload: { state, user, group },
  };
};
