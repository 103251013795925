import Quagga from "@ericblade/quagga2";
import { Alert, message } from "antd";
import Modal from "antd/lib/modal/Modal";

export function scanBarcode(file: any, fn?: (r: any) => void) {
  Quagga.decodeSingle(
    {
      src: file,
      numOfWorkers: 0, // Needs to be 0 when used within node
      inputStream: {
        size: 1200, // restrict input-size to be 800px in width (long-side)
      },
      locate: true,
      decoder: {
        readers: ["code_39_reader"],
        debug: {
          drawBoundingBox: false,
          showFrequency: false,
          drawScanline: false,
          showPattern: false,
        },
        multiple: false, // List of active readers
      },
      locator: {
        halfSample: true,
        patchSize: "medium", // x-small, small, medium, large, x-large
      },
    },
    function (result) {
        if(result){
      if (result.codeResult) {
        console.log("result", result.codeResult.code);
        message.success(result.codeResult.code);
        if (fn) {
          fn(result.codeResult.code);
        }
      } else {
        console.log("not detected");
        console.log(result);
        message.success("無法晰別條碼!");
      }
    }else{
        console.log("result empty!");
    }
    }
  );
}
